<template>
  <v-dialog
    id="form_view"
    v-if="bActive"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="40%"
    v-model="bActive"
  >
    <v-card :shaped="smUp" class="dialog_card_container">
      <!-- Start dialog title -->
      <v-container style="margin-top: 0px">
        <v-layout row justify-center>
          <p v-text="dialog_title" class="dialog_title_text" />
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <v-container>
        <v-layout row justify-center>
          <p class="text_description" v-text="quick_add_desc" />
        </v-layout>
      </v-container>
      <v-container>
        <!-- Center button layout -->
        <v-layout row justify-center>
          <!-- Limit button layout extension -->
          <v-layout row style="max-width: 80%">
            <v-btn color="red" text @click="close">{{cancel_btn_txt}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dense
              :loading="load_add_btn"
              :disabled="load_add_btn"
              @click="confirm_add"
            >{{submit_btn_txt}}</v-btn>
          </v-layout>
          <!-- Limit button layout extension -->
        </v-layout>
        <!-- Center button layout  -->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    quick_add_desc:
      "You are about to report an outage, ¿are you sure you want to proceed with this action?",
    submit_btn_txt: "Confirm",
    cancel_btn_txt: "Cancel",
    dialog_title: "Report an outage?",
    load_add_btn: false,
  }),
  props: {
    bActive: Boolean,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    redirect_user() {
      this.$router.push("/admin/outages");
      this.$router.go();
    },
    confirm_add() {
      this.load_add_btn = true;
      var outage = {
        iType: 0,
        sAlarmActivated: "-",
        sEstimatedRecoveryTime: "-",
        iStatus: 0,
        iLocation: 0,
      };
      var body = encode.encodeJSON(outage);
      db.post(`${uri}/api/outages`, body, {
        headers: {
          Authorization: this.usr_token,
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          localStorage.setItem("OutageEdit", JSON.stringify(resp.data.outage));
          var dateGoal = new Date(); // new Date(resp.data.outage.createdAt)
          dateGoal.setMinutes(dateGoal.getMinutes() + 30);
          localStorage.setItem("date-start", dateGoal);
          localStorage.setItem("timer-status", true);
          this.load_add_btn = false;
          const alert = {
            color: "success",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.close();
          this.redirect_user();
        })
        .catch((err) => {
          this.load_add_btn = false;
          const alert = {
            color: "fail",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  computed: {
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>
<style scoped>
.text_description {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  text-align: center;
  max-width: 80%;
}
.field_texts {
  margin-bottom: -25px;
  font-family: "Open sans", sans-serif;
  color: "black";
  font-size: 12px;
  letter-spacing: 0.1px;
}
.password_layout {
  width: 100%;
  margin-bottom: 10px;
}
.dialog_card_container {
  padding: 7.5%;
}
</style>