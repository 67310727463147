<template>
  <div>
    <v-navigation-drawer
      disable-resize-watcher
      stateless
      v-if="$vuetify.breakpoint.smAndUp"
      v-model="drawer"
      class="drawer_admin"
      :persistent="true"
      hide-overlay
      width="15%"
      color="#24347B"
      :mini-variant="miniVariant"
      :mini-variant-width="'8%'"
      :src="bg"
      dark
    >
      <v-layout column class="my-fill-height">
        <v-container style="background-color:white">
          <v-layout row wrap justify-center align-center>
            <v-flex sm12>
              <v-img contain src="/static/Imagen162x.png" alt="John"></v-img>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>

        <v-layout column>
          <v-list dense>
            <v-list-item
              elevation="0"
              active-class="#782C33"
              v-for="(item) in items"
              :key="item.to"
              :to="item.to"
            >
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <div>
                        <v-icon :color="'white'" v-html="item.icon" />
                      </div>
                    </span>
                  </template>
                  <span v-if="miniVariant" v-html="item.title" />
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :style="'color: white'"
                  class="primaryText--text menu-text"
                  v-text="item.title"
                />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <div>
                        <v-icon @click="showweb" :color="'white'" v-html="'mdi-web'" />
                      </div>
                    </span>
                  </template>
                  <span v-html="'Pagina web'" />
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  @click="showweb"
                  style="cursor: pointer;"
                  class="logout-tile"
                  v-text="'Pagina web'"
                />
              </v-list-item-content>
              <v-icon @click="showweb" :color="'white'" v-html="'mdi-menu-down'" />
            </v-list-item>

            <v-list-item
              v-if="dropped"
              elevation="0"
              active-class="#782C33"
              v-for="(item) in items2"
              :key="item.to"
              :to="item.to"
            >
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <div>
                        <v-icon :color="'white'" v-html="item.icon" />
                      </div>
                    </span>
                  </template>
                  <span v-if="miniVariant" v-html="item.title" />
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :style="'color: white'"
                  class="primaryText--text menu-text"
                  v-text="item.title"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-list class="logout-tile set_cursor">
            <v-list-item>
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <div>
                        <v-icon @click="logout" :color="'white'" v-html="'mdi-logout'" />
                      </div>
                    </span>
                  </template>
                  <span v-html="'Cerrar sesión'" />
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  @click="logout"
                  class="logout-tile"
                  :style="'white'"
                  v-text="'Cerrar sesión'"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-layout>
      </v-layout>
    </v-navigation-drawer>
    <v-card
      v-else
      style="position: fixed; top: 0; z-index: 100; width: 100%;"
      color="grey lighten-4"
      flat
      tile
    >
      <v-toolbar height="70px" style="overflow: hidden;" color="#24347B" dense>
        <v-layout row justify-center>
          <img
            style="max-width: 50px; margin-left: 15%"
            class="company_logo"
            src="/static/imagen_16.png"
          />
        </v-layout>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon :color="'white'">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card color="#24347B" dark>
            <v-list color="transparent">
              <v-list-item v-for="(item, i) in items" :key="i" :to="item.to">
                <v-icon style="font-size: 14px; margin-right: 10px">{{item.icon}}</v-icon>
                <v-list-item-title v-text="item.title" :style="'color:white; font-size: 14px'" />
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <div>
                          <v-icon @click="showweb" :color="'white'" v-html="'mdi-web'" />
                        </div>
                      </span>
                    </template>
                    <span v-html="'Cerrar sesión'" />
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    @click="showweb"
                    style="cursor: pointer;"
                    class="logout-tile"
                    v-text="'Pagina web'"
                  />
                </v-list-item-content>
                <v-icon @click="showweb" :color="'white'" v-html="'mdi-menu-down'" />
              </v-list-item>

              <v-list-item
                v-if="dropped"
                elevation="0"
                active-class="#782C33"
                v-for="(item) in items2"
                :key="item.to"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <div>
                          <v-icon :color="'white'" v-html="item.icon" />
                        </div>
                      </span>
                    </template>
                    <span v-if="miniVariant" v-html="item.title" />
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :style="'color: white'"
                    class="primaryText--text menu-text"
                    v-text="item.title"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-title
                  v-text="'Cerrar sesión'"
                  :style="'color:white; font-size: 14px'"
                />
                <v-icon style="font-size: 14px; margin-right: 10px">mdi-logout</v-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropped: false,
      items2: [
        {
          title: "Header",
          icon: "",
          to: "/admin/header",
        },
        {
          title: "Inicio",
          icon: "",
          to: "/admin/inicio",
        },
        {
          title: "¿Quiénes Somos?",
          icon: "",
          to: "/admin/about",
        },
        {
          title: "Servicios de manufactura",
          icon: "",
          to: "/admin/webmanufacture",
        },
        {
          title: "Servicios comerciales",
          icon: "",
          to: "/admin/webcomercial",
        },
        {
          title: "Contacto",
          icon: "",
          to: "/admin/contact",
        },
        {
          title: "Footer",
          icon: "",
          to: "/admin/footer",
        },
      ],
      items: [
        {
          title: "Servicios de manufactura",
          icon: "mdi-cog",
          to: "/admin/manufacture",
        },
        {
          title: "Servicios comerciales",
          icon: "mdi-domain",
          to: "/admin/comercial",
        },
        {
          title: "Mensajes",
          icon: "mdi-email",
          to: "/admin/mail",
        },
      ],
      drawer: true,
      color: "primary",
      right: false,
      miniVariant: false,
      expandOnHover: false,
      background: false,
    };
  },
  methods: {
    showweb() {
      this.dropped = !this.dropped;
    },
    closeConditional(e) {
      return (
        (window.innerWidth >= this.mobileBreakPoint && !this.drawer) ||
        !this.closeOnClick ||
        this.$el.contains(e.target)
      );
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then((resp) => {
          this.$store.commit("toggle_alert", resp);
          this.$router.go();
        })
        .catch((err) => {
          this.$store.commit("toggle_alert", err);
        });
    },
  },
  computed: {
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
  },
};
</script>

<style scoped>
.drawer_admin {
  background-color:#24347B ;
  overflow: visible;
  position: fixed;
}

.v-navigation-drawer {
  -webkit-box-shadow: 28px -1px 34px -34px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 28px -1px 34px -34px rgba(0, 0, 0, 0.75);
  box-shadow: 28px -1px 34px -34px rgba(0, 0, 0, 0.75);
}
.logout-tile {
  color: white;
  font-size: 12px;
}
.v-divider {
  background-color: #bf343d;
}
.set_cursor {
  cursor: pointer;
}
.my-fill-height {
  height: 100vh;
}
.company_name {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: "Aleo", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 20px;
  max-width: 150px;
}
.company_name_mobile {
  cursor: default;
  letter-spacing: 2px;
  margin: 20px;
  z-index: 1;
  font-family: "Aleo", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 15px;
  max-width: 150px;
}
.company_logo {
  height: 100%;
  width: 100%;
}
</style>
