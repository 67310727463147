const routes = [
	{
		path: '/admin',
		name: 'Admin',
		component: () => import('@/views/Admin'),
		meta: {
			requiresAuth: true,
			userType: 99
		},
		children: [
			{
				path: 'mail',
				name: 'Mail',
				component: () => import('@/views/admin/Mail'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},
			{
				path: 'manufacture',
				name: 'Manufacture',
				component: () => import('@/views/admin/Manufacture'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},
			{
				path: 'comercial',
				name: 'Comercial',
				component: () => import('@/views/admin/Comercial'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},
			{
				path: 'header',
				name: 'Header',
				component: () => import('@/views/admin/web/Header'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},
			{
				path: 'inicio',
				name: 'Inicio',
				component: () => import('@/views/admin/web/Home'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},			{
				path: 'about',
				name: 'About',
				component: () => import('@/views/admin/web/AboutUs'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},			{
				path: 'webmanufacture',
				name: 'WebManufacture',
				component: () => import('@/views/admin/web/WebManufacture'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},			{
				path: 'webcomercial',
				name: 'WebComercial',
				component: () => import('@/views/admin/web/WebComercial'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},			{
				path: 'contact',
				name: 'Contact',
				component: () => import('@/views/admin/web/Contact'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},			{
				path: 'footer',
				name: 'Footer',
				component: () => import('@/views/admin/web/Footer'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},
			{
				path: '*',
				name: 'Error',
				component: () => import('@/views/Error'),
				meta: {
					requiresAuth: true,
					userType: 99
				}
			},
			{
				path: '*',
				component: () => import('@/views/admin/Manufacture')
			}
		]
	}
];

export default routes;
