<template>
  <v-card
    :style="smUp ? 'background-color: transparent; margin-bottom: 50px' : ''"
    flat
  >
    <div>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 0%' : 'margin-top: 0%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp"
      >
        <v-flex xs12 sm6>
          <v-layout
            row
            style="margin-top: 5%"
            :justify-center="!smUp"
            :justify-start="smUp"
          >
            <p v-text="'Pagina web'" class="view_title_text" />
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12>
          <v-layout row :justify-center="!smUp" :justify-start="smUp">
            <p v-text="'Footer'" class="view_title_text2" />
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-container style="margin-left: -3%">
          <v-tabs
            v-if="$vuetify.breakpoint.mdAndUp"
            grow
            height="70"
            v-model="currentTab"
            active-class="active-tab"
          >
            <v-tab
              style="
                color: #000000;
                background-color: #fafafa;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
              "
              >{{ home.manuTitle }}</v-tab
            >
            <v-tab
              style="
                color: #000000;
                background-color: #fafafa;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
              "
              >{{ home.comerTitle }}</v-tab
            >
          </v-tabs>
          <v-tabs
            v-else
            fixed-tabs
            height="70"
            v-model="currentTab"
            active-class="active-tab"
          >
            <v-tab
              style="
                max-width: 40%;
                background-color: #fafafa;
                font-family: 'Montserrat', sans-serif;
                font-size: 8px;
              "
              >{{ home.manuTitle }}</v-tab
            >
            <v-tab
              style="
                max-width: 40%;
                background-color: #fafafa;
                font-family: 'Montserrat', sans-serif;
                font-size: 8px;
              "
              >{{ home.comerTitle }}</v-tab
            >
          </v-tabs>

          <v-layout row style="margin: 0" v-if="currentTab == 0">
            <h4>Datos de empresa</h4>
            <v-flex sm12>
              <v-layout row>
                <v-flex sm4 pa-3>
                  <v-layout row justify-left>
                    <p class="field_texts" v-text="'Dirección'" />
                  </v-layout>
                  <v-layout row justify-center class="password_layout">
                    <v-text-field
                      v-on:keyup.enter="set_login"
                      v-model="es1"
                      color="guest_primary"
                      placeholder="Dirección"
                    />
                  </v-layout>
                </v-flex>
                <v-flex sm4 pa-3>
                  <v-layout row justify-left>
                    <p class="field_texts" v-text="'Contacto'" />
                  </v-layout>
                  <v-layout row justify-center class="password_layout">
                    <v-text-field
                      v-on:keyup.enter="set_login"
                      v-model="es2"
                      color="guest_primary"
                      placeholder="Contacto"
                    />
                  </v-layout>
                </v-flex>
                <v-flex sm4 pa-3>
                  <v-layout row justify-left>
                    <p class="field_texts" v-text="'Teléfono'" />
                  </v-layout>
                  <v-layout row justify-center class="password_layout">
                    <v-text-field
                      v-on:keyup.enter="set_login"
                      v-model="es3"
                      color="guest_primary"
                      placeholder="Teléfono"
                    />
                  </v-layout>
                </v-flex>
              </v-layout>

              <h4>Redes sociales</h4>

              <v-flex sm12>
                <v-layout row>
                  <v-flex sm4 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Enlace a facebook'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="es4"
                        color="guest_primary"
                        placeholder="Enlace a facebook"
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex sm4 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Enlace a instagram'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="es5"
                        color="guest_primary"
                        placeholder="Enlace a instagram"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
          </v-layout>

          <!-- English Header Info -->

          <v-layout row style="margin: 0" v-else>
            <h4>Datos de empresa</h4>
            <v-flex sm12>
              <v-layout row>
                <v-flex sm4 pa-3>
                  <v-layout row justify-left>
                    <p class="field_texts" v-text="'Título'" />
                  </v-layout>
                  <v-layout row justify-center class="password_layout">
                    <v-text-field
                      v-on:keyup.enter="set_login"
                      v-model="en1"
                      color="guest_primary"
                      placeholder="Título"
                    />
                  </v-layout>
                </v-flex>
                <v-flex sm4 pa-3>
                  <v-layout row justify-left>
                    <p class="field_texts" v-text="'Contacto'" />
                  </v-layout>
                  <v-layout row justify-center class="password_layout">
                    <v-text-field
                      v-on:keyup.enter="set_login"
                      v-model="en2"
                      color="guest_primary"
                      placeholder="Contacto"
                    />
                  </v-layout>
                </v-flex>
                <v-flex sm4 pa-3>
                  <v-layout row justify-left>
                    <p class="field_texts" v-text="'Teléfono'" />
                  </v-layout>
                  <v-layout row justify-center class="password_layout">
                    <v-text-field
                      v-on:keyup.enter="set_login"
                      v-model="en3"
                      color="guest_primary"
                      placeholder="Teléfono"
                    />
                  </v-layout>
                </v-flex>
              </v-layout>

              <h4>Redes sociales</h4>

              <v-flex sm12>
                <v-layout row>
                  <v-flex sm4 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Enlace a facebook'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="en4"
                        color="guest_primary"
                        placeholder="Enlace a facebook"
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex sm4 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Enlace a instagram'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="en5"
                        color="guest_primary"
                        placeholder="Enlace a instagram"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
          </v-layout>
          <v-btn style="float: right" color="#56984D" @click="savesections" dark
            >Guardar cambios</v-btn
          >
        </v-container>
      </v-layout>
      <v-layout>
        <v-container>
          <!-- Start table container -->

          <!-- Loading animation -->
          <!-- End table container -->
          <div
            style="
              position: fixed;
              bottom: 0;
              text-align: center;
              justify-content: center;
              justify-items: center;
              width: 69%;
              margin-bottom: 20px;
            "
          >
            <span style="color: black; font-size: 16px">
              Powered by
              <a
                style="color: black"
                href="https://sofex.com.mx/"
                target="_blank"
                >Sofex Technologies</a
              >
              <img
                style="
                  margin-left: 8px;
                  position: relative;
                  top: 6px;
                  height: 25px;
                  width: 25px;
                "
                src="/static/sofexlogo.png"
                alt
              />
            </span>
          </div>
        </v-container>
      </v-layout>
    </div>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    addOrEdit: true,
    file: null,
    orderBy: "",
    bSeen: null,
    orderByStatus: "",
    imagenes: [],
    orderListEstatus: ["Todos", "Visto", "Sin ver"],
    ingredients_list: [],
    orderList: [
      "Todos",
      "Solicitud de empleo",
      "Solicitud de proveedor",
      "Información de nuestros servicios",
      "Otro",
    ],
        es1: "",
    es2: "",
    es3: "",
    es4: "",
    es5:"",
    en1:"",
    en2:"",
    en3:"",
    en4:"",
    en5:"",
    user_search: "",
    current_page: 1,
    num_pages: 0,
    total_users: 0,
    clients_list: [],
    add_client: false,
    bLoading: false,
    home: {
      manuTitle: "Español",
      comerTitle: "Inglés",
    },
    currentTab: 0,
  }),
  watch: {
    current_page: function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    },
    user_search: _.debounce(function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    }, 500),
  },
  mounted() {
    this.get_clients();
    this.getText();
  },
  methods: {
savesections() {
      var axios = require("axios");

      var data = JSON.stringify({
        spanish: {
          pageOne: {
            section: {
              background: this.information.spanish.pageOne.section.background,
              title: this.information.spanish.pageOne.section.title,
              description: this.information.spanish.pageOne.section.description,
            },
            title: this.information.spanish.pageOne.title,
            description: this.information.spanish.pageOne.description,
            button: this.information.spanish.pageOne.button,
            quote: this.information.spanish.pageOne.quote,
            subquote: this.information.spanish.pageOne.subquote,
            tabTitleLeft: this.information.spanish.pageOne.tabTitleLeft,
            tabTitleRight: this.information.spanish.pageOne.tabTitleRight,
          },
          pageTwo: {
            header: { title: this.information.spanish.pageTwo.header.title },
            sectionOne: {
              title: this.information.spanish.pageTwo.sectionOne.title,
              description: this.information.spanish.pageTwo.sectionOne
                .description,
            },
            sectionTwo: {
              title: this.information.spanish.pageTwo.sectionTwo.title,
              description: this.information.spanish.pageTwo.sectionTwo
                .description,
            },
            sectionThree: {
              title: this.information.spanish.pageTwo.sectionThree.title,
              description: this.information.spanish.pageTwo.sectionThree
                .description,
            },
          },
          pageThree: {
            header: { title: this.information.spanish.pageThree.header.title },
          },
          pageFour: {
            header: { title: this.information.spanish.pageFour.header.title },
          },
          pageFive: {
            header: { title: this.information.spanish.pageFive.header.title },
            description: this.information.spanish.pageFive.description,
          },
          footer: {
            address: this.es1,
            email: this.es2,
            phone: this.es3,
            facebookLink: this.es4,
            instagramLink: this.es5,
            copyright: this.information.spanish.footer.copyright,
          },
          navBarTitles: this.information.spanish.navBarTitles
        },
        english: {
          pageOne: {
            section: {
              background: this.information.english.pageOne.section.background,
              title: this.information.english.pageOne.section.title,
              description: this.information.english.pageOne.section.description,
            },
            title: this.information.english.pageOne.title,
            description: this.information.english.pageOne.description,
            button: this.information.english.pageOne.button,
            quote: this.information.english.pageOne.quote,
            subquote: this.information.english.pageOne.subquote,
            tabTitleLeft: this.information.english.pageOne.tabTitleLeft,
            tabTitleRight: this.information.english.pageOne.tabTitleRight,
          },
          pageTwo: {
            header: { title: this.information.english.pageTwo.header.title },
            sectionOne: {
              title: this.information.english.pageTwo.sectionOne.title,
              description: this.information.english.pageTwo.sectionOne
                .description,
            },
            sectionTwo: {
              title: this.information.english.pageTwo.sectionTwo.title,
              description: this.information.english.pageTwo.sectionTwo
                .description,
            },
            sectionThree: {
              title: this.information.english.pageTwo.sectionThree.title,
              description: this.information.english.pageTwo.sectionThree
                .description,
            },
          },
          pageThree: { header: { title: this.information.english.pageThree.header.title, } },
          pageFour: { header: { title: this.information.english.pageFour.header.title, } },
          pageFive: {
            header: { title: this.information.english.pageFive.header.title, },
            description:
              this.information.english.pageFive.description,
          },
          footer: {
            address: this.en1,
            email: this.en2,
            phone: this.en3,
            facebookLink: this.en4,
            instagramLink: this.en5,
            copyright: this.information.english.footer.copyright,
          },
          navBarTitles: this.information.english.navBarTitles
        },
      });

      let that = this;

      var config = {
        method: "put",
        url:
          "https://api.genvamex.com/api/information/spanish",
        headers: {
          language: "spanish",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNmNkMTQ0ZGQwZTVlNDkyNDNkMzJiNCIsImlhdCI6MTYwMTI3NzIyN30.-fBJKQahx4KG11HiVOiWDr1-c8CDFRmAXYjJ54gN3tQ",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          that.$store.commit("toggle_alert", {
            color: "success",
            text:"Información actulizada",
          });
        })
        .catch(function (error) {
        
          console.log(error);
        });
    },
    getText() {
      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url:
          "https://api.genvamex.com/api/information/",
        headers: {},
      };

      axios(config)
        .then(function (response) {
          that.information = response.data.information;

          console.log(response.data.information.english.footer.address)

          that.es1 = response.data.information.spanish.footer.address;

          that.es2 = response.data.information.spanish.footer.email;

          that.es3 = response.data.information.spanish.footer.phone;

          that.es4 = response.data.information.spanish.footer.facebookLink;

          that.es5 = response.data.information.spanish.footer.instagramLink;

          that.en1 = response.data.information.english.footer.address;

          that.en2 = response.data.information.english.footer.email;

          that.en3 = response.data.information.english.footer.phone;

          that.en4 = response.data.information.english.footer.facebookLink;

          that.en5 = response.data.information.english.footer.instagramLink;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getIngredients() {
      console.log("getIngredients");
      this.ingredients_list = [];
      let temp = {};
      for (let i = 0; i < 10; i++) {
        temp = {
          name: "Ingrediente " + i,
          mail: "fhenandez@sofex.com.mx",
          motive: i,
          phone: "8182594828",
          id: i,
          status: 0,
        };
        this.ingredients_list.push(temp);
      }
    },
    get_clients() {
      if (this.orderBy == "Todos") {
        this.orderBy = "";
      }

      if (this.orderByStatus == "Todos") {
        this.bSeen = null;
      } else if (this.orderByStatus == "Visto") {
        this.bSeen = true;
      } else if (this.orderByStatus == "Sin ver") {
        this.bSeen = false;
      }

      db.get(`${uri}/api/contacts`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          page: this.current_page,
          search: this.user_search,
          motive: this.orderBy,
          bSeen: this.bSeen,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.totalPages;
          this.ingredients_list = resp.data.contacts;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    get_clients_search() {
      this.bLoading = true;
      db.get(`${uri}/api/users`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          query: this.client_search,
          iPage: this.current_page,
          iPageSize: 15,
          iRole: 2,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.iTotalPages;
          this.total_users = resp.data.iTotalUsers;
          this.clients_list = resp.data.users;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
  components: {
    ClientAdd: () => import("@/components/sections/Admin/Clients/ClientAdd"),
    IngredientTable: () =>
      import("@/components/sections/Admin/Ingredients/IngredientTable"),
  },
};
</script>
<style scoped>
.total_user_text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  margin-right: 10%;
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}
.search_user {
  margin-right: 10%;
}
.search_user_mobile {
  max-width: 80%;
}
.active-tab {
  background-color: #24347b5c !important;
}

.password_layout {
  width: 100%;
  margin-bottom: 10px;
}

.field_texts {
  margin-bottom: -10px;
  font-family: "Open sans", sans-serif;
  color: #56984d !important;
  font-size: 16px;
  letter-spacing: 0.1px;
}
</style>