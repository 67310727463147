var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-navigation-drawer',{staticClass:"drawer_admin",attrs:{"disable-resize-watcher":"","stateless":"","persistent":true,"hide-overlay":"","width":"15%","color":"#24347B","mini-variant":_vm.miniVariant,"mini-variant-width":'8%',"src":_vm.bg,"dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-layout',{staticClass:"my-fill-height",attrs:{"column":""}},[_c('v-container',{staticStyle:{"background-color":"white"}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"sm12":""}},[_c('v-img',{attrs:{"contain":"","src":"/static/Imagen162x.png","alt":"John"}})],1)],1)],1),_c('v-divider'),_c('v-layout',{attrs:{"column":""}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.to,attrs:{"elevation":"0","active-class":"#782C33","to":item.to}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('div',[_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s(item.icon)}})],1)])]}}],null,true)},[(_vm.miniVariant)?_c('span',{domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e()])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primaryText--text menu-text",style:('color: white'),domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),_c('v-list-item',[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('div',[_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s('mdi-web')},on:{"click":_vm.showweb}})],1)])]}}],null,false,35167415)},[_c('span',{domProps:{"innerHTML":_vm._s('Pagina web')}})])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"logout-tile",staticStyle:{"cursor":"pointer"},domProps:{"textContent":_vm._s('Pagina web')},on:{"click":_vm.showweb}})],1),_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s('mdi-menu-down')},on:{"click":_vm.showweb}})],1),_vm._l((_vm.items2),function(item){return (_vm.dropped)?_c('v-list-item',{key:item.to,attrs:{"elevation":"0","active-class":"#782C33","to":item.to}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('div',[_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s(item.icon)}})],1)])]}}],null,true)},[(_vm.miniVariant)?_c('span',{domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e()])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primaryText--text menu-text",style:('color: white'),domProps:{"textContent":_vm._s(item.title)}})],1)],1):_vm._e()})],2),_c('v-spacer'),_c('v-list',{staticClass:"logout-tile set_cursor"},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('div',[_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s('mdi-logout')},on:{"click":_vm.logout}})],1)])]}}],null,false,1607066612)},[_c('span',{domProps:{"innerHTML":_vm._s('Cerrar sesión')}})])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"logout-tile",style:('white'),domProps:{"textContent":_vm._s('Cerrar sesión')},on:{"click":_vm.logout}})],1)],1)],1)],1)],1)],1):_c('v-card',{staticStyle:{"position":"fixed","top":"0","z-index":"100","width":"100%"},attrs:{"color":"grey lighten-4","flat":"","tile":""}},[_c('v-toolbar',{staticStyle:{"overflow":"hidden"},attrs:{"height":"70px","color":"#24347B","dense":""}},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('img',{staticClass:"company_logo",staticStyle:{"max-width":"50px","margin-left":"15%"},attrs:{"src":"/static/imagen_16.png"}})]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":'white'}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',{attrs:{"color":"#24347B","dark":""}},[_c('v-list',{attrs:{"color":"transparent"}},[_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to}},[_c('v-icon',{staticStyle:{"font-size":"14px","margin-right":"10px"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',{style:('color:white; font-size: 14px'),domProps:{"textContent":_vm._s(item.title)}})],1)}),_c('v-list-item',[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('div',[_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s('mdi-web')},on:{"click":_vm.showweb}})],1)])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s('Cerrar sesión')}})])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"logout-tile",staticStyle:{"cursor":"pointer"},domProps:{"textContent":_vm._s('Pagina web')},on:{"click":_vm.showweb}})],1),_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s('mdi-menu-down')},on:{"click":_vm.showweb}})],1),_vm._l((_vm.items2),function(item){return (_vm.dropped)?_c('v-list-item',{key:item.to,attrs:{"elevation":"0","active-class":"#782C33","to":item.to}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('div',[_c('v-icon',{attrs:{"color":'white'},domProps:{"innerHTML":_vm._s(item.icon)}})],1)])]}}],null,true)},[(_vm.miniVariant)?_c('span',{domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e()])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primaryText--text menu-text",style:('color: white'),domProps:{"textContent":_vm._s(item.title)}})],1)],1):_vm._e()}),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',{style:('color:white; font-size: 14px'),domProps:{"textContent":_vm._s('Cerrar sesión')}}),_c('v-icon',{staticStyle:{"font-size":"14px","margin-right":"10px"}},[_vm._v("mdi-logout")])],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }