<template>
  <v-card
    :style="smUp ? 'background-color: transparent; margin-bottom: 50px' : ''"
    flat
  >
    <div>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 0%' : 'margin-top: 0%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp"
      >
        <v-flex xs12 sm6>
          <v-layout
            row
            style="margin-top: 5%"
            :justify-center="!smUp"
            :justify-start="smUp"
          >
            <p v-text="'Pagina web'" class="view_title_text" />
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12>
          <v-layout row :justify-center="!smUp" :justify-start="smUp">
            <p v-text="'Header'" class="view_title_text2" />
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-container style="margin-left: -3%">
          <v-tabs
            v-if="$vuetify.breakpoint.mdAndUp"
            grow
            height="70"
            v-model="currentTab"
            active-class="active-tab"
          >
            <v-tab
              style="
                color: #000000;
                background-color: #fafafa;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
              "
              >{{ home.manuTitle }}</v-tab
            >
            <v-tab
              style="
                color: #000000;
                background-color: #fafafa;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
              "
              >{{ home.comerTitle }}</v-tab
            >
          </v-tabs>
          <v-tabs
            v-else
            fixed-tabs
            height="70"
            v-model="currentTab"
            active-class="active-tab"
          >
            <v-tab
              style="
                max-width: 40%;
                background-color: #fafafa;
                font-family: 'Montserrat', sans-serif;
                font-size: 8px;
              "
              >{{ home.manuTitle }}</v-tab
            >
            <v-tab
              style="
                max-width: 40%;
                background-color: #fafafa;
                font-family: 'Montserrat', sans-serif;
                font-size: 8px;
              "
              >{{ home.comerTitle }}</v-tab
            >
          </v-tabs>

          <v-layout row style="margin: 0" v-if="currentTab == 0">
            <v-flex sm12 pa-3>
              <h4 style="color: #56984d">Secciones</h4>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Sección 1'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar1"
                  color="guest_primary"
                  placeholder="Sección 1"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Sección 2'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar2"
                  color="guest_primary"
                  placeholder="Sección 2"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Sección 3'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar3"
                  color="guest_primary"
                  placeholder="Sección 3"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Sección 4'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar4"
                  color="guest_primary"
                  placeholder="Sección 4"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Sección 5'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar5"
                  color="guest_primary"
                  placeholder="Sección 5"
                />
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- English Header Info -->

          <v-layout row style="margin: 0" v-else>
            <v-flex sm12 pa-3>
              <h4 style="color: #56984d">Sections</h4>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Section 1'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar1en"
                  color="guest_primary"
                  placeholder="Section 1"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Section 2'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar2en"
                  color="guest_primary"
                  placeholder="Section 2"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Section 3'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar3en"
                  color="guest_primary"
                  placeholder="Section 3"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Section 4'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar4en"
                  color="guest_primary"
                  placeholder="Section 4"
                />
              </v-layout>
            </v-flex>
            <v-flex sm4 pa-3>
              <v-layout row justify-left>
                <p class="field_texts" v-text="'Section 5'" />
              </v-layout>
              <v-layout row justify-center class="password_layout">
                <v-text-field
                  v-on:keyup.enter="set_login"
                  v-model="esnavbar5en"
                  color="guest_primary"
                  placeholder="Section 5"
                />
              </v-layout>
            </v-flex>
          </v-layout>
          <v-btn
            style="float: right"
            color="#56984D"
            dark
            @click="savesections"
          >
            Guardar cambios
          </v-btn>
        </v-container>
      </v-layout>
      <v-layout>
        <v-container>
          <!-- Start table container -->

          <!-- Loading animation -->
          <!-- End table container -->
          <div
            style="
              position: fixed;
              bottom: 0;
              text-align: center;
              justify-content: center;
              justify-items: center;
              width: 69%;
              margin-bottom: 20px;
            "
          >
            <span style="color: black; font-size: 16px">
              Powered by
              <a
                style="color: black"
                href="https://sofex.com.mx/"
                target="_blank"
                >Sofex Technologies</a
              >
              <img
                style="
                  margin-left: 8px;
                  position: relative;
                  top: 6px;
                  height: 25px;
                  width: 25px;
                "
                src="/static/sofexlogo.png"
                alt
              />
            </span>
          </div>
        </v-container>
      </v-layout>
    </div>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    information: null,
    esnavbar1: "",
    esnavbar2: "",
    esnavbar3: "",
    esnavbar4: "",
    esnavbar5: "",
    esnavbar1en: "",
    esnavbar2en: "",
    esnavbar3en: "",
    esnavbar4en: "",
    esnavbar5en: "",
    addOrEdit: true,
    orderBy: "",
    bSeen: null,
    orderByStatus: "",
    orderListEstatus: ["Todos", "Visto", "Sin ver"],
    ingredients_list: [],
    orderList: [
      "Todos",
      "Solicitud de empleo",
      "Solicitud de proveedor",
      "Información de nuestros servicios",
      "Otro",
    ],
    user_search: "",
    current_page: 1,
    num_pages: 0,
    total_users: 0,
    clients_list: [],
    add_client: false,
    bLoading: false,
    home: {
      manuTitle: "Español",
      comerTitle: "Inglés",
    },
    currentTab: 0,
  }),
  watch: {
    current_page: function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    },
    user_search: _.debounce(function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    }, 500),
  },
  mounted() {
    this.getText();
    this.get_clients();
  },
  methods: {
    savesections() {
      var axios = require("axios");

      var data = JSON.stringify({
        spanish: {
          pageOne: {
            section: {
              background: this.information.spanish.pageOne.section.background,
              title: this.information.spanish.pageOne.section.title,
              description: this.information.spanish.pageOne.section.description,
            },
            title: this.information.spanish.pageOne.title,
            description: this.information.spanish.pageOne.description,
            button: this.information.spanish.pageOne.button,
            quote: this.information.spanish.pageOne.quote,
            subquote: this.information.spanish.pageOne.subquote,
            tabTitleLeft: this.information.spanish.pageOne.tabTitleLeft,
            tabTitleRight: this.information.spanish.pageOne.tabTitleRight,
          },
          pageTwo: {
            header: { title: this.information.spanish.pageTwo.header.title },
            sectionOne: {
              title: this.information.spanish.pageTwo.sectionOne.title,
              description: this.information.spanish.pageTwo.sectionOne
                .description,
            },
            sectionTwo: {
              title: this.information.spanish.pageTwo.sectionTwo.title,
              description: this.information.spanish.pageTwo.sectionTwo
                .description,
            },
            sectionThree: {
              title: this.information.spanish.pageTwo.sectionThree.title,
              description: this.information.spanish.pageTwo.sectionThree
                .description,
            },
          },
          pageThree: {
            header: { title: this.information.spanish.pageThree.header.title },
          },
          pageFour: {
            header: { title: this.information.spanish.pageFour.header.title },
          },
          pageFive: {
            header: { title: this.information.spanish.pageFive.header.title },
            description: this.information.spanish.pageFive.description,
          },
          footer: {
            address: this.information.spanish.footer.address,
            email: this.information.spanish.footer.email,
            phone: this.information.spanish.footer.phone,
            facebookLink: this.information.spanish.footer.facebookLink,
            instagramLink: this.information.spanish.footer.instagramLink,
            copyright: this.information.spanish.footer.copyright,
          },
          navBarTitles: [
            this.esnavbar1,
            this.esnavbar2,
            this.esnavbar3,
            this.esnavbar4,
            this.esnavbar5,
          ],
        },
        english: {
          pageOne: {
            section: {
              background: this.information.english.pageOne.section.background,
              title: this.information.english.pageOne.section.title,
              description: this.information.english.pageOne.section.description,
            },
            title: this.information.english.pageOne.title,
            description: this.information.english.pageOne.description,
            button: this.information.english.pageOne.button,
            quote: this.information.english.pageOne.quote,
            subquote: this.information.english.pageOne.subquote,
            tabTitleLeft: this.information.english.pageOne.tabTitleLeft,
            tabTitleRight: this.information.english.pageOne.tabTitleRight,
          },
          pageTwo: {
            header: { title: this.information.english.pageTwo.header.title },
            sectionOne: {
              title: this.information.english.pageTwo.sectionOne.title,
              description: this.information.english.pageTwo.sectionOne
                .description,
            },
            sectionTwo: {
              title: this.information.english.pageTwo.sectionTwo.title,
              description: this.information.english.pageTwo.sectionTwo
                .description,
            },
            sectionThree: {
              title: this.information.english.pageTwo.sectionThree.title,
              description: this.information.english.pageTwo.sectionThree
                .description,
            },
          },
          pageThree: { header: { title: this.information.english.pageThree.header.title, } },
          pageFour: { header: { title: this.information.english.pageFour.header.title, } },
          pageFive: {
            header: { title: this.information.english.pageFive.header.title, },
            description:
              this.information.english.pageFive.description,
          },
          footer: {
            address: this.information.english.footer.address,
            email: this.information.english.footer.email,
            phone: this.information.english.footer.phone,
            facebookLink: this.information.english.footer.facebookLink,
            instagramLink: this.information.english.footer.instagramLink,
            copyright: this.information.english.footer.copyright,
          },
          navBarTitles: [
            this.esnavbar1en,
            this.esnavbar2en,
            this.esnavbar3en,
            this.esnavbar4en,
            this.esnavbar5en,
          ],
        },
      });

      let that = this;

      var config = {
        method: "put",
        url:
          "https://api.genvamex.com/api/information/spanish",
        headers: {
          language: "spanish",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNmNkMTQ0ZGQwZTVlNDkyNDNkMzJiNCIsImlhdCI6MTYwMTI3NzIyN30.-fBJKQahx4KG11HiVOiWDr1-c8CDFRmAXYjJ54gN3tQ",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          that.$store.commit("toggle_alert", {
            color: "success",
            text:"Información actulizada",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getText() {
      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url:
          "https://api.genvamex.com/api/information/",
        headers: {},
      };

      axios(config)
        .then(function (response) {
          that.information = response.data.information;

                    that.imagenes =
            response.data.information.images.pageOne.section.gallery;
          that.imagenes2 =
            response.data.information.images.pageOne.sectionTwo.gallery;

          that.esnavbar1 = response.data.information.spanish.navBarTitles[0];

          that.esnavbar2 = response.data.information.spanish.navBarTitles[1];

          that.esnavbar3 = response.data.information.spanish.navBarTitles[2];

          that.esnavbar4 = response.data.information.spanish.navBarTitles[3];

          that.esnavbar5 = response.data.information.spanish.navBarTitles[4];

          that.esnavbar1en = response.data.information.english.navBarTitles[0];

          that.esnavbar2en = response.data.information.english.navBarTitles[1];

          that.esnavbar3en = response.data.information.english.navBarTitles[2];

          that.esnavbar4en = response.data.information.english.navBarTitles[3];

          that.esnavbar5en = response.data.information.english.navBarTitles[4];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getIngredients() {
      console.log("getIngredients");
      this.ingredients_list = [];
      let temp = {};
      for (let i = 0; i < 10; i++) {
        temp = {
          name: "Ingrediente " + i,
          mail: "fhenandez@sofex.com.mx",
          motive: i,
          phone: "8182594828",
          id: i,
          status: 0,
        };
        this.ingredients_list.push(temp);
      }
    },
    get_clients() {
      if (this.orderBy == "Todos") {
        this.orderBy = "";
      }

      if (this.orderByStatus == "Todos") {
        this.bSeen = null;
      } else if (this.orderByStatus == "Visto") {
        this.bSeen = true;
      } else if (this.orderByStatus == "Sin ver") {
        this.bSeen = false;
      }

      db.get(`${uri}/api/contacts`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          page: this.current_page,
          search: this.user_search,
          motive: this.orderBy,
          bSeen: this.bSeen,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.totalPages;
          this.ingredients_list = resp.data.contacts;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    get_clients_search() {
      this.bLoading = true;
      db.get(`${uri}/api/users`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          query: this.client_search,
          iPage: this.current_page,
          iPageSize: 15,
          iRole: 2,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.iTotalPages;
          this.total_users = resp.data.iTotalUsers;
          this.clients_list = resp.data.users;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
  components: {
    ClientAdd: () => import("@/components/sections/Admin/Clients/ClientAdd"),
    IngredientTable: () =>
      import("@/components/sections/Admin/Ingredients/IngredientTable"),
  },
};
</script>
<style scoped>
.total_user_text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  margin-right: 10%;
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}
.search_user {
  margin-right: 10%;
}
.search_user_mobile {
  max-width: 80%;
}
.active-tab {
  background-color: #24347b5c !important;
}

.password_layout {
  width: 100%;
  margin-bottom: 10px;
}

.field_texts {
  margin-bottom: -10px;
  font-family: "Open sans", sans-serif;
  color: #56984d !important;
  font-size: 16px;
  letter-spacing: 0.1px;
}
</style>