<template>
  <v-card
    v-if="!this.$vuetify.breakpoint.mdAndUp"
    style="height: 100%; width: 100%; position: absolute"
    flat
    tile
    class="banner_guest"
    :img="'/static/ContactBanner.png'"
  >
    <v-card class="banner_guest filter_card"></v-card>
  </v-card>
</template>
<script>
export default {
  props: {
    smUp: Boolean,
  },
};
</script>