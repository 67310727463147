import Vue from 'vue';
import Router from 'vue-router';

// importing VueX state management for verifying auth
import store from './store';
// import file that manages non-auth user routes
import router_operator from '@/router_operator';
// import file that manages auth user routes
import router_admin from '@/router_admin';
// import file that manages auth user routes
import router_guest from '@/router_guest';

Vue.use(Router);

// merge route files to a single component to inititate the vue router instance
var allRoutes = [];
allRoutes = allRoutes.concat(router_operator, router_admin, router_guest);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: allRoutes
});

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
	Vue.use(require('vue-analytics').default, {
		id: process.env.VUE_APP_GOOGLE_ANALYTICS,
		router,
		autoTracking: {
			page: process.env.NODE_ENV !== 'development'
		}
	});
}

// initiate authentication filter for private routes
// Type of user logged in
// store.state.userType
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!store.state.login_in_status) {
			next({
				path: '/'
			});
		} else {
			// check user type required and user type logged
			if (to.meta.userType == store.state.user_type) {
				next();
			} else {
				next({
					path: '/'
				});
			}
		}
	} else if (!to.matched.some((record) => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (store.state.login_in_status) {
			next({
				path: '/'
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
