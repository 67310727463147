<template>
  <v-dialog
    id="form_view"
    v-if="bActive"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="40%"
    v-model="bActive"
  >
    <v-card :shaped="smUp" class="dialog_card_container">
      <!-- Start close icon -->
      <v-layout row justify-end>
        <v-btn style="margin-right: 10px" icon fab small color="grey" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <!-- End close icon -->
      <!-- Start dialog title -->
      <v-container style="margin-top: -30px">
        <v-layout row justify-center>
          <p v-text="dialog_title" class="dialog_title_text" />
          <!-- start info alert -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                style="font-size: 20px; margin: 5px; margin-top: -20px"
                color="primary"
                v-on="on"
              >mdi-information</v-icon>
            </template>
            <span>Re-uploading files will overwrite previously uploaded files.</span>
          </v-tooltip>
          <!-- end info alert -->
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <!-- Start tabs component -->
      <v-container class="tabs_wrapper">
        <v-layout row justify-center>
          <v-tabs v-model="tab" fixed-tabs background-color="white">
            <!--  <v-tab
              class="tab_item">
              <p
                class="file_tab_title"> 
                {{initialText}}
              </p>
             <v-tooltip 
                v-if="outageDetails.sPdfKey"
                bottom>
                <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 20px; margin-bottom: -20px"
                      color="green"
                      v-on="on">
                      mdi-comment-alert
                    </v-icon>
                </template>
                <span>        
                  A file was previously uploaded to {{initialText}}.
                </span>
              </v-tooltip>
            </v-tab>-->
            <v-tab class="tab_item">
              <p class="file_tab_title">{{startText}}</p>
              <!-- Start previous upload alert -->
              <v-tooltip v-if="outageDetails.sInitialPdfKey" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    style="font-size: 20px; margin-bottom: -20px"
                    color="green"
                    v-on="on"
                  >mdi-comment-alert</v-icon>
                </template>
                <span>A file was previously uploaded to {{startText}}.</span>
              </v-tooltip>
              <!-- End previous upload alert -->
            </v-tab>
            <v-tab class="tab_item">
              <p class="file_tab_title">{{finalText}}</p>
              <!-- Start previous upload alert -->
              <v-tooltip v-if="outageDetails.sFinalPdfKey" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    style="font-size: 20px; margin-bottom: -20px"
                    color="green"
                    v-on="on"
                  >mdi-comment-alert</v-icon>
                </template>
                <span>A file was previously uploaded to {{finalText}}.</span>
              </v-tooltip>
              <!-- End previous upload alert -->
            </v-tab>
          </v-tabs>
        </v-layout>
      </v-container>
      <!-- End tabs component -->
      <!-- Start tab content -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-layout row justify-center>
                <v-file-input
                  :show-size="true"
                  :value="outageDetails.sInitialPdfKey"
                  :style="smUp ? 'max-width: 80%; cursor: pointer !important':'100px; cursor: pointer !important'"
                  v-model="file_object_initial"
                  label="Report file INITIAL"
                  filled
                  prepend-icon="mdi-file"
                  type="file"
                ></v-file-input>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!--         <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-layout
                row
                justify-center>
                <v-file-input
                  :show-size="true"
                  :value="outageDetails.sPdfKey"
                  :style="smUp ? 'max-width: 80%; cursor: pointer !important':'100px; cursor: pointer !important'"
                  v-model="file_object"
                  label="Archivo de reporte"
                  filled
                  prepend-icon="mdi-file"
                  type="file"
                ></v-file-input>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>-->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-layout row justify-center>
                <v-file-input
                  :show-size="true"
                  :value="outageDetails.sFinalPdfKey"
                  :style="smUp ? 'max-width: 80%; cursor: pointer !important':'100px; cursor: pointer !important'"
                  v-model="file_object_final"
                  label="Report file FINAL"
                  filled
                  prepend-icon="mdi-file"
                  type="file"
                ></v-file-input>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!-- End tab content -->

      <v-container>
        <!-- Center button layout -->
        <v-layout row justify-center>
          <!-- Limit button layout extension -->
          <v-layout row style="max-width: 80%">
            <v-btn color="red" text @click="close">{{cancel_btn_txt}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dense
              :loading="load_edit_btn"
              :disabled="allowSubmit"
              @click="confirm_add"
            >{{submit_btn_txt}}</v-btn>
          </v-layout>
          <!-- Limit button layout extension -->
        </v-layout>
        <!-- Center button layout  -->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    tab: 0,
    initialText: "Preliminary",
    startText: "Initial",
    finalText: "Final",
    title_text: "Name",
    description_text: "Company",
    file_object: null,
    file_object_final: null,
    file_object_initial: null,
    submit_btn_txt: "Save",
    cancel_btn_txt: "Cancel",
    dialog_title: "Upload report",
    new_notifications: {
      sTitle: "",
      sDescription: "",
    },
    load_edit_btn: false,
  }),
  props: {
    bActive: Boolean,
    outage_id: String,
    outageDetails: Object,
  },
  watch: {
    file_object: function () {},
  },
  methods: {
    close() {
      this.$emit("close");
    },
    reset_form() {
      // this.file_object = null
      this.file_object_initial = null;
      this.file_object_final = null;
    },
    async confirm_add() {
      var formData = new FormData();
      if (this.tab == 0) {
        formData.append("file", this.file_object_initial);
        console.log(formData[0]);
      } else if (this.tab == 1) {
        formData.append("file", this.file_object_final);
        console.log(formData[0]);
      }
      this.load_edit_btn = true;
      // set possible types of certificates
      var types = [
        // 'sPdfKey',
        "sInitialPdfKey",
        "sFinalPdfKey",
      ];
      // tabs correspond to their index in types list
      db.put(
        `${uri}/api/outages/` + this.outage_id + "/pdfs/" + types[this.tab],
        formData,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            Authorization: this.usr_token,
          },
        }
      )
        .then((resp) => {
          this.load_edit_btn = false;
          const alert = {
            color: "success",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.close();
          this.reset_form();
          this.$emit("update");
        })
        .catch((err) => {
          this.load_edit_btn = false;
          const alert = {
            color: "fail",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  computed: {
    allowSubmit() {
      // checks to see if the tab file is filled
      if (this.load_edit_btn) {
        return true;
      } else if (
        this.load_edit_btn ||
        (this.tab === 0 && this.file_object_initial == null)
      ) {
        return true;
      } /* else if (this.load_edit_btn || (this.tab === 1 && this.file_object == null)) {
        return true
      } */ else if (
        this.load_edit_btn ||
        (this.tab === 1 && this.file_object_final == null)
      ) {
        return true;
      } else {
        return false;
      }
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>
<style scoped>
.uploadedText {
  font-family: "Open sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
.tab_item {
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.113);
  border-bottom-width: 0.5px !important;
}
.tabs_wrapper {
  margin-top: -40px;
  margin-bottom: 20px;
}
.file_tab_title {
  text-transform: none !important;
  font-family: "Open sans", sans-serif;
  color: rgba(0, 0, 0, 0.698);
  font-size: 10px;
  margin: 10px;
  margin-bottom: -8px;
}
.field_texts {
  margin-bottom: -25px;
  font-family: "Open sans", sans-serif;
  color: "black";
  font-size: 12px;
  letter-spacing: 0.1px;
}
.password_layout {
  width: 100%;
  margin-bottom: 10px;
}
.dialog_card_container {
  padding: 0%;
}
.v-text-field__slot {
  cursor: pointer;
}
</style>