<template>
  <v-main class="white pt-0">
    <router-view />
  </v-main>
</template>

<script>
  export default {
    metaInfo () {
      return {
        titleTemplate: 'Instituto de Psicoterapias'
      }
    }
  }
</script>
