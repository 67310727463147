<template>
  <v-card
    :flat="!smUp"
    rounded
    :style="
      smUp
        ? 'padding: 15px; margin-left: -5%;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 15px;opacity: 1;'
        : ''
    "
  >
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      :items-per-page="20"
    >
      <template v-slot:item="props">
        <tr class="application_table_row">
          <td class="text-left">
            <p v-html="props.item.spanish.name" class="text_content" />
          </td>

          <td class="text-left">
            <p class="text_content" v-html="props.item.spanish.description"></p>
          </td>

          <td class="text-right">
            <v-btn fab small icon text>
              <v-icon color="black" @click="edit_service(props.item)"
                >mdi-pencil</v-icon
              >
            </v-btn>
            <v-btn fab small icon text>
              <v-icon color="red" @click="delete_client(props.item._id)"
                >mdi-delete</v-icon
              >
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog
      overlay-color="primary"
      overlay-opacity=".97"
      persistent
      :fullscreen="smUp ? false : true"
      max-width="40%"
      v-model="bActive"
      style="
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 12px #00000076;
        border-radius: 10px;
        opacity: 1;
      "
    >
      <v-card :shaped="smUp" flat class="dialog_card_container">
        <!-- Start close icon -->
        <v-layout row justify-end>
          <v-btn
            style="margin-right: 10px"
            icon
            fab
            small
            color="grey"
            text
            @click="bActive = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <!-- End close icon -->
        <!-- Start dialog title -->
        <v-container style="margin-top: -30px">
          <v-layout row justify-center>
            <p v-text="dialog_title" class="dialog_title_text" />
          </v-layout>
        </v-container>
        <!-- End dialog title -->
        <!-- Start description text -->
        <v-container style="margin-top: -30px">
          <v-layout row justify-center>
            <p v-text="dialog_desc" class="dialog_description_text" />
          </v-layout>
        </v-container>
        <!-- End description text -->
        <v-container>
          <!-- Center button layout -->
          <v-layout row justify-center>
            <!-- Limit button layout extension -->
            <v-layout row style="max-width: 80%">
              <v-btn color="red" text @click="bActive = false">{{
                cancel_btn_txt
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" dense @click="confirm_delete">{{
                submit_btn_txt
              }}</v-btn>
            </v-layout>
            <!-- Limit button layout extension -->
          </v-layout>
          <!-- Center button layout  -->
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog1"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="white">
          <v-btn icon dark @click="dialog1 = false">
            <v-icon color="#56984D">mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title style="color: #56984d">Regresar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-container>
            <v-card
              style="
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 17px;
              "
            >
              <v-container>
                <v-tabs
                  v-if="$vuetify.breakpoint.mdAndUp"
                  grow
                  height="70"
                  v-model="currentTab"
                  active-class="active-tab"
                >
                  <v-tab
                    style="
                      color: #000000;
                      background-color: #fafafa;
                      font-family: 'Raleway', sans-serif;
                      font-weight: 600;
                    "
                    >{{ home.manuTitle }}</v-tab
                  >
                  <v-tab
                    style="
                      color: #000000;
                      background-color: #fafafa;
                      font-family: 'Raleway', sans-serif;
                      font-weight: 600;
                    "
                    >{{ home.comerTitle }}</v-tab
                  >
                </v-tabs>
                <v-tabs
                  v-else
                  fixed-tabs
                  height="70"
                  v-model="currentTab"
                  active-class="active-tab"
                >
                  <v-tab
                    style="
                      max-width: 40%;
                      background-color: #fafafa;
                      font-family: 'Montserrat', sans-serif;
                      font-size: 8px;
                    "
                    >{{ home.manuTitle }}</v-tab
                  >
                  <v-tab
                    style="
                      max-width: 40%;
                      background-color: #fafafa;
                      font-family: 'Montserrat', sans-serif;
                      font-size: 8px;
                    "
                    >{{ home.comerTitle }}</v-tab
                  >
                </v-tabs>

                <v-layout row style="margin: 0" v-if="currentTab == 0">
                  <v-flex sm6 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Título'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="sTitulo"
                        color="guest_primary"
                        placeholder="Escribir el título del servicio"
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex sm1></v-flex>
                  <v-flex sm12 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Descripción'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-textarea
                        v-on:keyup.enter="set_login"
                        v-model="sDescripcion"
                        color="guest_primary"
                        placeholder="Escribe una descripción del producto…"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout row style="margin: 0" v-else>
                  <v-flex sm6 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Título'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="sTitle"
                        color="guest_primary"
                        placeholder="Escribir el título del servicio"
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex sm1></v-flex>
                  <v-flex sm12 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Descripción'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-textarea
                        v-on:keyup.enter="set_login"
                        v-model="sDescription"
                        color="guest_primary"
                        placeholder="Escribe una descripción del producto…"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <br />
            <v-card
              style="
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 17px;
              "
            >
              <v-container>
                <v-tabs
                  v-if="$vuetify.breakpoint.mdAndUp"
                  grow
                  height="70"
                  v-model="currentTabImage"
                  active-class="active-tab"
                >
                  <v-tab
                    style="
                      color: #000000;
                      background-color: #fafafa;
                      font-family: 'Raleway', sans-serif;
                      font-weight: 600;
                    "
                    >Imágenes del servicio</v-tab
                  >
                  <v-tab
                    style="
                      color: #000000;
                      background-color: #fafafa;
                      font-family: 'Raleway', sans-serif;
                      font-weight: 600;
                    "
                    >Imagenes de ejemplo</v-tab
                  >
                </v-tabs>

                <v-layout row style="margin: 0" v-if="currentTabImage == 0">
                  <template v-for="(node, index) in ServiceImages">
<v-flex :key="index" xs10 sm2 md2 style="margin: 10px">
                      1800 x 1200 pixeles
                      <v-img
                        contain
                        min-width="200"
                        min-height="200"
                        max-width="200"
                        max-height="200"
                        :src="ServiceImages[index]"
                        @click="deleteImg(index)"
                      >
                        <v-btn
                          v-if="ServiceImages[index] != '/static/NoImage.png'"
                          color="red"
                          rounded
                        >
                          X
                        </v-btn>
                      </v-img>
                      <br />
                      <v-file-input
                        accept="image/x-png,image/gif,image/jpeg"
                        :label="getImageNameService(index)"
                        @change="uploadService(index)"
                        v-model="files[index]"
                        filled
                        prepend-icon=""
                      ></v-file-input>
                    </v-flex>
                  </template>
                </v-layout>
                <v-layout row style="margin: 0" v-else>
                  <template v-for="(node, index) in ExampleImages">
                  <v-flex :key="index" xs10 sm2 md2 style="margin: 10px">
                      1800 x 1200 pixeles
                      <v-img
                        contain
                        min-width="200"
                        min-height="200"
                        max-width="200"
                        max-height="200"
                        :src="ExampleImages[index]"
                        @click="deleteExample(index)"
                      >
                      <v-btn
                          v-if="ExampleImages[index] != '/static/NoImage.png'"
                          color="red"
                          rounded
                        >
                          X
                        </v-btn>
                      </v-img>
                      <br />
                      <v-file-input
                        accept="image/x-png,image/gif,image/jpeg"
                        :label="getImageNameExamples(index)"
                        @change="uploadExample(index)"
                        v-model="Examplefiles[index]"
                        filled
                        prepend-icon=""
                      ></v-file-input>
                    </v-flex>
                  </template>
                </v-layout>
              </v-container>
            </v-card>

            <v-layout column style="margin: 0">
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5></v-flex>
              <v-flex xs12 sm5>
                <v-layout row :justify-center="!smUp" :justify-end="smUp">
                  <v-btn
                    style="margin-top: 40px; margin-right: 4%"
                    height="60px"
                    width="260px"
                    text
                    color="red"
                    @click="dialog1 = false"
                    dense
                    >Cancelar</v-btn
                  >
                  <v-btn
                    style="margin-top: 40px"
                    height="60px"
                    width="260px"
                    color="secondary"
                    @click="saveManufacture"
                    dense
                    >Guardar</v-btn
                  >
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-list>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    ServiceImages: [
      "/static/NoImage.png",
      "/static/NoImage.png",
      "/static/NoImage.png",
      "/static/NoImage.png",
      "/static/NoImage.png",
    ],
    ExampleImages : [
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
      ],
    file: null,
    files: [null, null, null, null, null],
    Examplefiles : [null, null, null, null, null,null, null, null, null, null],
    bActive: false,
    dialog1: false,
    sTitle: "",
    currentTab: 0,
    currentTabImage: 0,
    sDescription: "",
    sTitulo: "",
    sDescripcion: "",
    home: {
      manuTitle: "Español",
      comerTitle: "Inglés",
    },
    service_to_edit: "",
    client_to_edit: {},
    client_to_delete: "",
    submit_btn_txt: "Aceptar",
    cancel_btn_txt: "Cancelar",
    dialog_title: "Eliminar servicio",
    dialog_desc:
      "Estás a punto de eliminar el servicio ¿Estás seguro de realizar esta acción?",
    b_edit_client: false,
    b_delete_client: false,
    delete_icon: "mdi-delete",
    edit_icon: "mdi-pencil",
    headers: [
      { text: "Nombre", width: "250px", value: "sName", align: "left" },
      { text: "Descripcion", value: "sName", align: "left" },
      { text: "Acciones", width: "200px", align: "right" },
    ],
  }),
  filters: {
    toCurrency(value) {
      value = Number(value);
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    formatDate(date) {
      /*var d = date.split(/\D+/g).map(function(v) {
        return parseInt(v, 10);
      });
      let newDate =  new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]);*/
      date = new Date(date);
      date.setHours(24);
      let monthArray = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sept",
        "Oct",
        "Nov",
        "Dic",
      ];
      let day = date.getDate();
      if (day < 10) day = "0" + day;
      let month = date.getMonth();
      let year = date.getFullYear();
      return `${day}/${monthArray[month]}/${year}`;
    },
  },
  methods: {
    deleteExample(index){
       let that = this;

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", null);


      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        `https://api.genvamex.com/api/services/${this.service_to_edit}/images?position=${index}`,
        config
      )
        .then(function (resp) {
          that.ExampleImages[index] = '/static/NoImage.png'
          that.getImage(index);
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    deleteImg(index) {
      let that = this;

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", null);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        `https://api.genvamex.com/api/services/${this.service_to_edit}/images?position=${index}`,
        config
      )
        .then(function (resp) {
          that.ServiceImages[index] = '/static/NoImage.png'
          that.getImage(index);
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    getImageNameExamples(myword) {
      var segment_str = this.ExampleImages[myword]; // return segment1/segment2/segment3/segment4
      var segment_array = segment_str.split("/");
      var last_segment = segment_array.pop();

      if(last_segment == "NoImage.png")
      {
        return "Subir Imagen"
      }
      
      return last_segment;
    },
        getImageNameService(myword) {
      var segment_str = this.ServiceImages[myword]; // return segment1/segment2/segment3/segment4
      var segment_array = segment_str.split("/");
      var last_segment = segment_array.pop();

      if(last_segment == "NoImage.png")
      {
        return "Subir Imagen"
      }
      
      return last_segment;
    },
    edit_client(client) {
      this.client_to_edit = client;
      this.b_edit_client = true;
    },
    edit_service(client) {
      this.dialog1 = true;
      this.service_to_edit = client._id;
      this.sTitulo = client.spanish.name;
      this.sTitle = client.english.name;
      this.sDescription = client.english.description;
      this.sDescripcion = client.spanish.description;

      this.ServiceImages = [
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
      ];

      this.ExampleImages = [
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
        "/static/NoImage.png",
      ];

      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url: `https://api.genvamex.com/api/services/${this.service_to_edit}`,
        headers: {},
      };

      axios(config)
        .then(function (response) {
          for (let i = 0; i < response.data.service[0].urls.length; i++) {
            if (response.data.service[0].urls[i] != "") {
              that.ServiceImages[i] = response.data.service[0].urls[i];
              that.$forceUpdate();
            }
          }

          for (
            let i = 0;
            i < response.data.service[0].exampleUrls.length;
            i++
          ) {
            if (response.data.service[0].exampleUrls[i] != "") {
              that.ExampleImages[i] = response.data.service[0].exampleUrls[i];
              that.$forceUpdate();
            }
          }
        })
        .catch(function (error) {
        });
    },
    delete_client(client) {
      this.client_to_delete = client;
      this.bActive = true;
    },
    update() {
      this.$emit("update");
    },
    uploadExample(index) {
      let that = this;

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", this.Examplefiles[index]);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        `https://api.genvamex.com/api/services/${this.service_to_edit}/exampleImages?position=${index}`,
        config
      )
        .then(function (resp) {
          that.getImage(index);
        })
        .catch(function (err) {
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    uploadService(index) {
      let that = this;

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", this.files[index]);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        `https://api.genvamex.com/api/services/${this.service_to_edit}/images?position=${index}`,
        config
      )
        .then(function (resp) {
          that.getImage(index);
        })
        .catch(function (err) {
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    getImage(index) {
      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url: `https://api.genvamex.com/api/services/${this.service_to_edit}`,
        headers: {},
      };

      axios(config)
        .then(function (response) {
          for (let i = 0; i < response.data.service[0].urls.length; i++) {
            if (response.data.service[0].urls[i] != "") {
              that.ServiceImages[i] = response.data.service[0].urls[i];
            }
          }

          for (
            let i = 0;
            i < response.data.service[0].exampleUrls.length;
            i++
          ) {
            if (response.data.service[0].exampleUrls[i] != "") {
              that.ExampleImages[i] = response.data.service[0].exampleUrls[i];
              that.$forceUpdate();
            }
          }

          that.$forceUpdate();
        })
        .catch(function (error) {
        });
    },
    saveManufacture() {
      let that = this;

      var axios = require("axios");
      var data = JSON.stringify({
        english: { description: this.sDescription, name: this.sTitle },
        spanish: { description: this.sDescripcion, name: this.sTitulo },
        bAvailable: true,
      });

      var config = {
        method: "put",
        url:
          "https://api.genvamex.com/api/services/" +
          this.service_to_edit,
        headers: {
          Authorization: this.usr_token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      db(config)
        .then(function (response) {
          const alert = {
            color: "success",
            text: "Información actualizada",
          };
          that.$store.commit("toggle_alert", alert);
          that.sTitulo = "";
          that.sDescripcion = "";
          that.sTitle = "";
          that.sDescription = "";
          that.dialog1 = false;
          that.update();
        })
        .catch(function (error) {
          const alert = {
            color: "fail",
            text: error.response.data.message,
          };
          that.$store.commit("toggle_alert", alert);
        });
    },

    confirm_delete() {
      db.delete(`${uri}/api/services/` + this.client_to_delete, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
      })
        .then((resp) => {
          const alert = {
            color: "success",
            text: "Elemento eliminado de manera correcta",
          };
          this.$store.commit("toggle_alert", alert);
          this.update();
          this.bActive = false;
        })
        .catch((err) => {
          const alert = {
            color: "fail",
            text: err.response.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.bActive = false;
        });
    },
  },
  components: {
    ClientEdit: () => import("@/components/sections/Admin/Clients/ClientEdit"),
    OrderDelete: () => import("@/components/sections/Admin/Orders/DeleteOrder"),
  },
  props: {
    items: Array,
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
};
</script>
<style scoped>
.attribute_flex {
  margin-top: 18px;
}
hr {
  height: 0.5px;
  color: rgba(140, 140, 140, 0.112);
  background-color: rgba(114, 114, 114, 0.112);
  border-color: transparent;
}
.attribute_name {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
  font-family: "Open sans", sans-serif;
}
.attribute_value {
  color: #000000;
  font-size: 12px;
  font-family: "Open sans", sans-serif;
}
.application_table_row {
  cursor: pointer;
}
.text_content {
  margin-top: 15px;
  color: #000000;
  font-size: 12px;
  font-family: "Open sans", sans-serif !important;
}

.dialog_description_text {
  font-family: "Raleway", sans-serif;
  color: "black";
  font-size: 16px;
  letter-spacing: 0.1px;
  max-width: 80%;
  text-align: center;
}
.field_texts {
  margin-bottom: -25px;
  font-family: "Raleway", sans-serif;
  color: "black";
  font-size: 12px;
  letter-spacing: 0.1px;
}

.active-tab {
  background-color: #24347b5c !important;
}
</style>