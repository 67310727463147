<template>
  <v-card
    :style="smUp ? 'background-color: transparent; margin-bottom: 50px' : ''"
    flat
  >
    <div>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 0%' : 'margin-top: 0%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp"
      >
        <v-flex xs12 sm6>
          <v-layout
            row
            style="margin-top: 5%"
            :justify-center="!smUp"
            :justify-start="smUp"
          >
            <p v-text="'Pagina web'" class="view_title_text" />
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12>
          <v-layout row :justify-center="!smUp" :justify-start="smUp">
            <p v-text="'Inicio'" class="view_title_text2" />
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-container style="margin-left: -3%">
          <v-tabs
            v-if="$vuetify.breakpoint.mdAndUp"
            grow
            height="70"
            v-model="currentTab"
            active-class="active-tab"
          >
            <v-tab
              style="
                color: #000000;
                background-color: #fafafa;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
              "
              >{{ home.manuTitle }}</v-tab
            >
            <v-tab
              style="
                color: #000000;
                background-color: #fafafa;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
              "
              >{{ home.comerTitle }}</v-tab
            >
          </v-tabs>
          <v-tabs
            v-else
            fixed-tabs
            height="70"
            v-model="currentTab"
            active-class="active-tab"
          >
            <v-tab
              style="
                max-width: 40%;
                background-color: #fafafa;
                font-family: 'Montserrat', sans-serif;
                font-size: 8px;
              "
              >{{ home.manuTitle }}</v-tab
            >
            <v-tab
              style="
                max-width: 40%;
                background-color: #fafafa;
                font-family: 'Montserrat', sans-serif;
                font-size: 8px;
              "
              >{{ home.comerTitle }}</v-tab
            >
          </v-tabs>

          <v-layout row style="margin: 0" v-if="currentTab == 0">
            <h4>Banner inicial</h4>
            <v-flex sm12 pa-3>
              <v-layout row style="margin: 0">
                <v-flex sm4>
                  <v-carousel
                    width="300"
                    height="250"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <v-carousel-item
                      v-for="(item, i) in imagenes"
                      :key="i"
                      :src="item"
                    >
                      <v-card-title
                        class="justify-center"
                        style="
                          color: white;
                          text-shadow: 10 10 10px #234;
                          font-size: 40px;
                        "
                        >{{ i + 1 }}</v-card-title
                      >
                    </v-carousel-item>
                  </v-carousel>

                  <v-flex sm12 pa-3 style="margin-left: 10%">
                    <v-layout row style="margin: 0">
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles1">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-file-input
                          id="fileUpload1"
                          accept="image/x-png,image/gif,image/jpeg"
                          v-show="false"
                          v-model="file"
                          @change="upload1"
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles2">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            accept="image/x-png,image/gif,image/jpeg"
                            id="fileUpload2"
                            v-show="false"
                            label="Imagen 2"
                            v-model="file2"
                            @change="upload2"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles3">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            accept="image/x-png,image/gif,image/jpeg"
                            id="fileUpload3"
                            v-show="false"
                            style="margin: 5%"
                            label="Imagen 3"
                            v-model="file3"
                            @change="upload3"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-flex>

                <v-flex sm8>
                  <v-layout row wrap>
                    <v-flex sm1></v-flex>

                    <v-flex sm5 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Título'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-text-field
                          v-on:keyup.enter="set_login"
                          v-model="es1"
                          color="guest_primary"
                          placeholder="Título"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm6 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Botón'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-text-field
                          v-on:keyup.enter="set_login"
                          v-model="es2"
                          color="guest_primary"
                          placeholder="Botón"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm1></v-flex>

                    <v-flex sm11 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Descripción 1'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="es3"
                          color="guest_primary"
                          placeholder="Descripción 1"
                        ></v-textarea>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <h4>Sección 1</h4>

            <v-flex sm12 pa-3>
              <v-layout row style="margin: 0">
                <v-flex sm12>
                  <v-layout row wrap>
                    <v-flex sm12 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Quote'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="es6"
                          color="guest_primary"
                          placeholder="Quote"
                        />
                      </v-layout>
                    </v-flex>

                    <v-flex sm12 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'subQuote'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="es7"
                          color="guest_primary"
                          placeholder="SubQuote"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm12 pa-3>
                      <v-layout row justify-left> </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <h4>Sección 2</h4>

            <v-flex sm12>
              <v-layout row style="margin: 0">
                <v-flex sm4 style="margin-top: 1%">
                  <v-carousel
                    width="300"
                    height="250"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <v-carousel-item
                      v-for="(item, i) in imagenes2"
                      :key="i"
                      :src="item"
                    >
                      <v-card-title
                        class="justify-center"
                        style="
                          color: white;
                          text-shadow: 10 10 10px #234;
                          font-size: 40px;
                        "
                        >{{ i + 1 }}</v-card-title
                      >
                    </v-carousel-item>
                  </v-carousel>

                  <v-flex sm12 pa-3 style="margin-left: 10%">
                    <v-layout row style="margin: 0">
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles4">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-file-input
                          id="fileUpload4"
                          accept="image/x-png,image/gif,image/jpeg"
                          v-show="false"
                          v-model="file4"
                          @change="upload4"
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles5">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            accept="image/x-png,image/gif,image/jpeg"
                            id="fileUpload5"
                            v-show="false"
                            label="Imagen 2"
                            v-model="file5"
                            @change="upload5"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles6">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            accept="image/x-png,image/gif,image/jpeg"
                            id="fileUpload6"
                            v-show="false"
                            style="margin: 5%"
                            label="Imagen 3"
                            v-model="file6"
                            @change="upload6"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-flex>

                <v-flex sm8>
                  <v-layout row wrap>
                    <v-flex sm1></v-flex>
                    <v-flex sm11 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Título'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-text-field
                          v-on:keyup.enter="set_login"
                          v-model="es4"
                          color="guest_primary"
                          placeholder="Título"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm1></v-flex>

                    <v-flex sm11 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Descripción'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="es5"
                          color="guest_primary"
                          placeholder="Descripción"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm12 pa-3>
                      <v-layout row justify-left> </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- English Header Info -->

          <v-layout row style="margin: 0" v-else>
            <h4>Banner inicial</h4>

            <v-flex sm12 pa-3>
              <v-layout row style="margin: 0">
                <v-flex sm4>
                  <v-carousel
                    width="300"
                    height="250"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <v-carousel-item
                      v-for="(item, i) in imagenes"
                      :key="i"
                      :src="item"
                    >
                      <v-card-title
                        class="justify-center"
                        style="
                          color: white;
                          text-shadow: 10 10 10px #234;
                          font-size: 40px;
                        "
                        >{{ i + 1 }}</v-card-title
                      >
                    </v-carousel-item>
                  </v-carousel>

                  <v-flex sm12 pa-3 style="margin-left: 10%">
                    <v-layout row style="margin: 0">
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles1">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-file-input
                          id="fileUpload1"
                          accept="image/x-png,image/gif,image/jpeg"
                          v-show="false"
                          v-model="file"
                          @change="upload1"
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles2">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            accept="image/x-png,image/gif,image/jpeg"
                            id="fileUpload2"
                            v-show="false"
                            label="Imagen 2"
                            v-model="file2"
                            @change="upload2"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles3">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            id="fileUpload3"
                            accept="image/x-png,image/gif,image/jpeg"
                            v-show="false"
                            style="margin: 5%"
                            label="Imagen 3"
                            v-model="file3"
                            @change="upload3"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-flex>

                <v-flex sm8>
                  <v-layout row wrap>
                    <v-flex sm1></v-flex>

                    <v-flex sm5 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Título'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-text-field
                          v-on:keyup.enter="set_login"
                          v-model="en1"
                          color="guest_primary"
                          placeholder="Título"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm6 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Botón'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-text-field
                          v-on:keyup.enter="set_login"
                          v-model="en2"
                          color="guest_primary"
                          placeholder="Botón"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm1></v-flex>

                    <v-flex sm11 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Descripción 1'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="en3"
                          color="guest_primary"
                          placeholder="Descripción 1"
                        ></v-textarea>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <h4>Sección 1</h4>

            <v-flex sm12>
              <v-layout row style="margin: 0">
                <v-flex sm12>
                  <v-layout row wrap>
                    <v-flex sm12 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Quote'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="en6"
                          color="guest_primary"
                          placeholder="Quote"
                        />
                      </v-layout>
                    </v-flex>

                    <v-flex sm12 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Subquote'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="en7"
                          color="guest_primary"
                          placeholder="Subquote"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm12 pa-3>
                      <v-layout row justify-left> </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <h4>Sección 2</h4>

            <v-flex sm12>
              <v-layout row style="margin: 0">
                <v-flex sm4 style="margin-top: 1%">
                  <v-carousel
                    width="300"
                    height="250"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <v-carousel-item
                      v-for="(item, i) in imagenes2"
                      :key="i"
                      :src="item"
                    >
                      <v-card-title
                        class="justify-center"
                        style="
                          color: white;
                          text-shadow: 10 10 10px #234;
                          font-size: 40px;
                        "
                        >{{ i + 1 }}</v-card-title
                      >
                    </v-carousel-item>
                  </v-carousel>

                  <v-flex sm12 pa-3 style="margin-left: 10%">
                    <v-layout row style="margin: 0">
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles4">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-file-input
                          id="fileUpload4"
                          accept="image/x-png,image/gif,image/jpeg"
                          v-show="false"
                          v-model="file4"
                          @change="upload4"
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles5">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            accept="image/x-png,image/gif,image/jpeg"
                            id="fileUpload5"
                            v-show="false"
                            label="Imagen 2"
                            v-model="file5"
                            @change="upload5"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                      <v-flex sm4 pa-3>
                        <v-btn icon color="black" @click="chooseFiles6">
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>
                        <v-layout row justify-center class="password_layout">
                          <v-file-input
                            accept="image/x-png,image/gif,image/jpeg"
                            id="fileUpload6"
                            v-show="false"
                            style="margin: 5%"
                            label="Imagen 3"
                            v-model="file6"
                            @change="upload6"
                            filled
                            prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-flex>

                <v-flex sm8>
                  <v-layout row wrap>
                    <v-flex sm1></v-flex>
                    <v-flex sm11 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Título'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-text-field
                          v-on:keyup.enter="set_login"
                          v-model="en4"
                          color="guest_primary"
                          placeholder="Título"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm1></v-flex>

                    <v-flex sm11 pa-3>
                      <v-layout row justify-left>
                        <p class="field_texts" v-text="'Descripción'" />
                      </v-layout>
                      <v-layout row justify-center class="password_layout">
                        <v-textarea
                          style="white-space: pre-wrap"
                          v-on:keyup.enter="set_login"
                          v-model="en5"
                          color="guest_primary"
                          placeholder="Descripción"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex sm12 pa-3>
                      <v-layout row justify-left> </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-btn style="float: right" color="#56984D" dark @click="savesections"
            >Guardar cambios</v-btn
          >
        </v-container>
      </v-layout>
      <v-layout>
        <v-container>
          <!-- Start table container -->

          <!-- Loading animation -->
          <!-- End table container -->
          <div
            style="
              position: fixed;
              bottom: 0;
              text-align: center;
              justify-content: center;
              justify-items: center;
              width: 69%;
              margin-bottom: 20px;
            "
          >
            <span style="color: black; font-size: 16px">
              Powered by
              <a
                style="color: black"
                href="https://sofex.com.mx/"
                target="_blank"
                >Sofex Technologies</a
              >
              <img
                style="
                  margin-left: 8px;
                  position: relative;
                  top: 6px;
                  height: 25px;
                  width: 25px;
                "
                src="/static/sofexlogo.png"
                alt
              />
            </span>
          </div>
        </v-container>
      </v-layout>
    </div>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    imgname: "",
    es1: "",
    es2: "",
    es3: "",
    es4: "",
    es5: "",
    es6: "",
    es7: "",
    en1: "",
    en2: "",
    en3: "",
    en4: "",
    en5: "",
    en6: "",
    en7: "",
    addOrEdit: true,
    file: null,
    file2: null,
    file3: null,
    file4: null,
    file5: null,
    file6: null,
    orderBy: "",
    bSeen: null,
    orderByStatus: "",
    imagenes: [],
    orderListEstatus: ["Todos", "Visto", "Sin ver"],
    ingredients_list: [],
    orderList: [
      "Todos",
      "Solicitud de empleo",
      "Solicitud de proveedor",
      "Información de nuestros servicios",
      "Otro",
    ],
    user_search: "",
    current_page: 1,
    num_pages: 0,
    total_users: 0,
    clients_list: [],
    imagenes2: [],
    add_client: false,
    bLoading: false,
    home: {
      manuTitle: "Español",
      comerTitle: "Inglés",
    },
    currentTab: 0,
  }),
  watch: {
    current_page: function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    },
    user_search: _.debounce(function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    }, 500),
  },
  mounted() {
    this.get_clients();
    this.getText();
  },
  methods: {
    chooseFiles1() {
      document.getElementById("fileUpload1").click();
    },
    chooseFiles2() {
      document.getElementById("fileUpload2").click();
    },
    chooseFiles3() {
      document.getElementById("fileUpload3").click();
    },
    chooseFiles4() {
      document.getElementById("fileUpload4").click();
    },
    chooseFiles5() {
      document.getElementById("fileUpload5").click();
    },
    chooseFiles6() {
      document.getElementById("fileUpload6").click();
    },
    savesections() {
      var axios = require("axios");

      var data = JSON.stringify({
        spanish: {
          pageOne: {
            section: {
              background: this.information.spanish.pageOne.section.background,
              title: this.es4,
              description: this.es5,
            },
            title: this.es1,
            description: this.es3,
            button: this.es2,
            quote: this.es6,
            subquote: this.es7,
            tabTitleLeft: this.information.spanish.pageOne.tabTitleLeft,
            tabTitleRight: this.information.spanish.pageOne.tabTitleRight,
          },
          pageTwo: {
            header: { title: this.information.spanish.pageTwo.header.title },
            sectionOne: {
              title: this.information.spanish.pageTwo.sectionOne.title,
              description: this.information.spanish.pageTwo.sectionOne
                .description,
            },
            sectionTwo: {
              title: this.information.spanish.pageTwo.sectionTwo.title,
              description: this.information.spanish.pageTwo.sectionTwo
                .description,
            },
            sectionThree: {
              title: this.information.spanish.pageTwo.sectionThree.title,
              description: this.information.spanish.pageTwo.sectionThree
                .description,
            },
          },
          pageThree: {
            header: { title: this.information.spanish.pageThree.header.title },
          },
          pageFour: {
            header: { title: this.information.spanish.pageFour.header.title },
          },
          pageFive: {
            header: { title: this.information.spanish.pageFive.header.title },
            description: this.information.spanish.pageFive.description,
          },
          footer: {
            address: this.information.spanish.footer.address,
            email: this.information.spanish.footer.email,
            phone: this.information.spanish.footer.phone,
            facebookLink: this.information.spanish.footer.facebookLink,
            instagramLink: this.information.spanish.footer.instagramLink,
            copyright: this.information.spanish.footer.copyright,
          },
          navBarTitles: this.information.spanish.navBarTitles,
        },
        english: {
          pageOne: {
            section: {
              background: this.information.english.pageOne.section.background,
              title: this.en4,
              description: this.en5,
            },
            title: this.en1,
            description: this.en3,
            button: this.en2,
            quote: this.en6,
            subquote: this.en7,
            tabTitleLeft: this.information.english.pageOne.tabTitleLeft,
            tabTitleRight: this.information.english.pageOne.tabTitleRight,
          },
          pageTwo: {
            header: { title: this.information.english.pageTwo.header.title },
            sectionOne: {
              title: this.information.english.pageTwo.sectionOne.title,
              description: this.information.english.pageTwo.sectionOne
                .description,
            },
            sectionTwo: {
              title: this.information.english.pageTwo.sectionTwo.title,
              description: this.information.english.pageTwo.sectionTwo
                .description,
            },
            sectionThree: {
              title: this.information.english.pageTwo.sectionThree.title,
              description: this.information.english.pageTwo.sectionThree
                .description,
            },
          },
          pageThree: {
            header: { title: this.information.english.pageThree.header.title },
          },
          pageFour: {
            header: { title: this.information.english.pageFour.header.title },
          },
          pageFive: {
            header: { title: this.information.english.pageFive.header.title },
            description: this.information.english.pageFive.description,
          },
          footer: {
            address: this.information.english.footer.address,
            email: this.information.english.footer.email,
            phone: this.information.english.footer.phone,
            facebookLink: this.information.english.footer.facebookLink,
            instagramLink: this.information.english.footer.instagramLink,
            copyright: this.information.english.footer.copyright,
          },
          navBarTitles: this.information.english.navBarTitles,
        },
      });

      let that = this;

      var config = {
        method: "put",
        url:
          "https://api.genvamex.com/api/information/spanish",
        headers: {
          language: "spanish",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNmNkMTQ0ZGQwZTVlNDkyNDNkMzJiNCIsImlhdCI6MTYwMTI3NzIyN30.-fBJKQahx4KG11HiVOiWDr1-c8CDFRmAXYjJ54gN3tQ",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          that.$store.commit("toggle_alert", {
            color: "success",
            text: "Información actulizada",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getText() {
      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url:
          "https://api.genvamex.com/api/information/",
        headers: {},
      };

      axios(config)
        .then(function (response) {
          that.information = response.data.information;

          that.imagenes =
            response.data.information.images.pageOne.section.gallery;
          that.imagenes2 =
            response.data.information.images.pageOne.sectionTwo.gallery;

          var segment_str = that.imagenes2[0]; // return segment1/segment2/segment3/segment4
          var segment_array = segment_str.split("/");
          var last_segment = segment_array.pop();

          that.imgname = last_segment;

          that.es1 = response.data.information.spanish.pageOne.title;
          that.es2 = response.data.information.spanish.pageOne.button;
          that.es3 = response.data.information.spanish.pageOne.description;
          that.es4 = response.data.information.spanish.pageOne.section.title;
          that.es5 =
            response.data.information.spanish.pageOne.section.description;
          that.es6 = response.data.information.spanish.pageOne.quote;
          that.es7 = response.data.information.spanish.pageOne.subquote;

          that.en1 = response.data.information.english.pageOne.title;
          that.en2 = response.data.information.english.pageOne.button;
          that.en3 = response.data.information.english.pageOne.description;
          that.en4 = response.data.information.english.pageOne.section.title;
          that.en5 =
            response.data.information.english.pageOne.section.description;
          that.en6 = response.data.information.english.pageOne.quote;
          that.en7 = response.data.information.english.pageOne.subquote;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    upload2() {
      let that = this;

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", that.file2);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        "https://api.genvamex.com/api/information?page=pageOne&section=section&type=gallery&position=1",
        config
      )
        .then(function (resp) {
          that.getImage();
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    upload3() {
      let that = this;

      console.log("entre");

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", that.file3);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        "https://api.genvamex.com/api/information?page=pageOne&section=section&type=gallery&position=2",
        config
      )
        .then(function (resp) {
          that.getImage();
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    upload4() {
      let that = this;

      console.log("entre");

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", that.file4);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        "https://api.genvamex.com/api/information?page=pageOne&section=sectionTwo&type=gallery&position=0",
        config
      )
        .then(function (resp) {
          that.getImage();
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    upload5() {
      let that = this;

      console.log("entre");

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", that.file5);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        "https://api.genvamex.com/api/information?page=pageOne&section=sectionTwo&type=gallery&position=1",
        config
      )
        .then(function (resp) {
          that.getImage();
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    upload6() {
      let that = this;

      console.log("entre");

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", that.file6);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        "https://api.genvamex.com/api/information?page=pageOne&section=sectionTwo&type=gallery&position=2",
        config
      )
        .then(function (resp) {
          that.getImage();
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    upload1() {
      let that = this;

      var axios = require("axios");

      var FormData = require("form-data");

      let data = new FormData();
      data.append("file", that.file);

      var config = {
        method: "post",
        headers: {
          Authorization: that.usr_token,
          "Content-Type": "image/png",
        },
        data: data,
      };

      axios(
        "https://api.genvamex.com/api/information?page=pageOne&section=section&type=gallery&position=0",
        config
      )
        .then(function (resp) {
          that.getImage();
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    getImage() {
      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url:
          "https://api.genvamex.com/api/information/",
        headers: {},
      };

      axios(config)
        .then(function (response) {
          that.imagenes =
            response.data.information.images.pageOne.section.gallery;
          that.imagenes2 =
            response.data.information.images.pageOne.sectionTwo.gallery;

          var segment_str = that.imagenes2[0]; // return segment1/segment2/segment3/segment4
          var segment_array = segment_str.split("/");
          var last_segment = segment_array.pop();

          that.imgname = last_segment;

          that.$forceUpdate();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getIngredients() {
      console.log("getIngredients");
      this.ingredients_list = [];
      let temp = {};
      for (let i = 0; i < 10; i++) {
        temp = {
          name: "Ingrediente " + i,
          mail: "fhenandez@sofex.com.mx",
          motive: i,
          phone: "8182594828",
          id: i,
          status: 0,
        };
        this.ingredients_list.push(temp);
      }
    },
    get_clients() {
      if (this.orderBy == "Todos") {
        this.orderBy = "";
      }

      if (this.orderByStatus == "Todos") {
        this.bSeen = null;
      } else if (this.orderByStatus == "Visto") {
        this.bSeen = true;
      } else if (this.orderByStatus == "Sin ver") {
        this.bSeen = false;
      }

      db.get(`${uri}/api/contacts`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          page: this.current_page,
          search: this.user_search,
          motive: this.orderBy,
          bSeen: this.bSeen,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.totalPages;
          this.ingredients_list = resp.data.contacts;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    get_clients_search() {
      this.bLoading = true;
      db.get(`${uri}/api/users`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          query: this.client_search,
          iPage: this.current_page,
          iPageSize: 15,
          iRole: 2,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.iTotalPages;
          this.total_users = resp.data.iTotalUsers;
          this.clients_list = resp.data.users;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
  components: {
    ClientAdd: () => import("@/components/sections/Admin/Clients/ClientAdd"),
    IngredientTable: () =>
      import("@/components/sections/Admin/Ingredients/IngredientTable"),
  },
};
</script>
<style scoped>
.total_user_text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  margin-right: 10%;
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}
.search_user {
  margin-right: 10%;
}
.search_user_mobile {
  max-width: 80%;
}
.active-tab {
  background-color: #24347b5c !important;
}

.password_layout {
  width: 100%;
  margin-bottom: 10px;
}

.field_texts {
  margin-bottom: -10px;
  font-family: "Open sans", sans-serif;
  color: #56984d !important;
  font-size: 16px;
  letter-spacing: 0.1px;
}
</style>