<template>
  <v-dialog
    v-if="bActive"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="40%"
    v-model="bActive"
    style="background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 12px #00000076;
border-radius: 10px;
opacity: 1;"
  >
    <v-card :shaped="smUp" flat class="dialog_card_container">
      <!-- Start close icon -->
      <v-layout row justify-end>
        <v-btn style="margin-right: 10px" icon fab small color="grey" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <!-- End close icon -->
      <!-- Start dialog title -->
      <v-container style="margin-top: -30px">
        <v-layout row justify-center>
          <p v-text="dialog_title" class="dialog_title_text" />
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <!-- Start description text -->
      <v-container style="margin-top: -30px">
        <v-layout row justify-center>
          <p v-text="dialog_desc" class="dialog_description_text" />
        </v-layout>
      </v-container>
      <!-- End description text -->
      <v-container>
        <!-- Center button layout -->
        <v-layout row justify-center>
          <!-- Limit button layout extension -->
          <v-layout row style="max-width: 80%">
            <v-btn color="red" text @click="close">{{cancel_btn_txt}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dense
              :loading="load_delete_btn"
              :disabled="load_delete_btn"
              @click="confirm_delete"
            >{{submit_btn_txt}}</v-btn>
          </v-layout>
          <!-- Limit button layout extension -->
        </v-layout>
        <!-- Center button layout  -->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    submit_btn_txt: "Aceptar",
    cancel_btn_txt: "Cancelar",
    dialog_title: "Eliminar servicio",
    dialog_desc:
      "Estás a punto de eliminar el servicio ¿Estás seguro de realizar esta acción?",
    user_client: {
      _id: "",
    },
    load_delete_btn: false,
  }),
  props: {
    bActive: Boolean,
    client_id: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    update() {
      this.$emit("update");
    },
    confirm_delete() {
      this.load_delete_btn = true;
      db.put(`${uri}/api/services/` + this.client_id, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
      })
        .then((resp) => {
          this.load_delete_btn = false;
          const alert = {
            color: "success",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.close();
          this.update();
        })
        .catch((err) => {
          this.load_delete_btn = false;
          const alert = {
            color: "fail",
            text: err.response.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  computed: {
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>
<style scoped>
.dialog_description_text {
  font-family: "Raleway", sans-serif;
  color: "black";
  font-size: 16px;
  letter-spacing: 0.1px;
  max-width: 80%;
  text-align: center;
}
.field_texts {
  margin-bottom: -25px;
  font-family: "Raleway", sans-serif;
  color: "black";
  font-size: 12px;
  letter-spacing: 0.1px;
}
.password_layout {
  width: 100%;
  margin-bottom: 10px;
}
</style>