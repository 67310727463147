<template>
  <v-card :style="smUp ? 'background-color: transparent; margin-bottom: 50px':''" flat>
    <div>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 0%': 'margin-top: 0%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp"
      >
        <v-flex xs12 sm6>
          <v-layout row style="margin-top:5%" :justify-center="!smUp" :justify-start="smUp">
            <p v-text="'Mensajes'" class="view_title_text" />
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-container>
          <v-card flat :style="smUp ? 'margin-left: -8%;':''">
            <v-layout row style="margin: 0;">
              <v-flex sm4>
                <v-select
                  style="margin: 25px"
                  height="50px"
                  v-model="orderBy"
                  :items="orderList"
                  label="Motivo"
                  @change="get_clients"
                ></v-select>
              </v-flex>
              <v-flex sm4>
                <v-select
                  style="margin: 25px"
                  height="50px"
                  v-model="orderByStatus"
                  :items="orderListEstatus"
                  label="Estatus"
                  @change="get_clients"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>
      </v-layout>
      <v-layout>
        <v-container>
          <!-- Start table container -->
          <div v-if="!bLoading">
            <IngredientTable :items="ingredients_list" @update="get_clients" />
          </div>
          <!-- Loading animation -->
          <v-card flat style="height: 100%; width: 100%; background-color: transparent" v-else>
            <v-layout :style="smUp ? 'max-width: 90%; margin-top: 10%':''" row justify-center>
              <v-progress-circular indeterminate rotate color="primary" size="100" width="1" />
            </v-layout>
          </v-card>
          <!-- Loading animation -->
          <!-- End table container -->
          <div
            style="position: fixed; bottom: 0;text-align: center; justify-content: center; justify-items: center; width:69%; margin-bottom:20px;"
          >
            <span style="color: black; font-size: 16px;">
              Powered by
              <a
                style="color: black;"
                href="https://sofex.com.mx/"
                target="_blank"
              >Sofex Technologies</a>
              <img
                style="margin-left: 8px;position: relative; top: 6px;height: 25px; width: 25px;"
                src="/static/sofexlogo.png"
                alt
              />
            </span>
          </div>
        </v-container>
      </v-layout>
    </div>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    addOrEdit: true,
    orderBy: "",
    bSeen: null,
    orderByStatus: "",
    orderListEstatus: ["Todos", "Visto", "Sin ver"],
    ingredients_list: [],
    orderList: [
      "Todos",
      "Solicitud de empleo",
      "Solicitud de proveedor",
      "Información de nuestros servicios",
      "Otro",
    ],
    user_search: "",
    current_page: 1,
    num_pages: 0,
    total_users: 0,
    clients_list: [],
    add_client: false,
    bLoading: false,
  }),
  watch: {
    current_page: function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    },
    user_search: _.debounce(function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    }, 500),
  },
  mounted() {
    this.get_clients();
  },
  methods: {
    getIngredients() {
      console.log("getIngredients");
      this.ingredients_list = [];
      let temp = {};
      for (let i = 0; i < 10; i++) {
        temp = {
          name: "Ingrediente " + i,
          mail: "fhenandez@sofex.com.mx",
          motive: i,
          phone: "8182594828",
          id: i,
          status: 0,
        };
        this.ingredients_list.push(temp);
      }
    },
    get_clients() {
      if (this.orderBy == "Todos") {
        this.orderBy = "";
      }

      if (this.orderByStatus == "Todos") {
        this.bSeen = null;
      } else if (this.orderByStatus == "Visto") {
        this.bSeen = true;
      } else if (this.orderByStatus == "Sin ver") {
        this.bSeen = false;
      }

      db.get(`${uri}/api/contacts`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          page: this.current_page,
          search: this.user_search,
          motive: this.orderBy,
          bSeen: this.bSeen,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          console.log(resp.data.contacts)
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.totalPages;
          this.ingredients_list = resp.data.contacts;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    get_clients_search() {
      this.bLoading = true;
      db.get(`${uri}/api/users`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          query: this.client_search,
          iPage: this.current_page,
          iPageSize: 15,
          iRole: 2,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          console.log(resp.data.users)
          this.num_pages = resp.data.iTotalPages;
          this.total_users = resp.data.iTotalUsers;
          this.clients_list = resp.data.users;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
  components: {
    ClientAdd: () => import("@/components/sections/Admin/Clients/ClientAdd"),
    IngredientTable: () =>
      import("@/components/sections/Admin/Ingredients/IngredientTable"),
  },
};
</script>
<style scoped>



.v-list-item .primary--text .v-list-item--active .v-list-item--link .theme--light{
      color: #000000 !important;
  caret-color:#000000 !important;
}

.v-input .v-input--is-label-active .v-input--is-dirty .v-input--is-focused .theme--light .v-text-field .v-text-field--is-booted .v-select .primary--text{
    color: #000000 !important;
  caret-color:#000000 !important;
}

.v-application.primary--text{
  color: #000000 !important;
  caret-color:#000000 !important;
}

.total_user_text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  margin-right: 10%;
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}
.search_user {
  margin-right: 10%;
}
.search_user_mobile {
  max-width: 80%;
}
</style>