<template>
  <v-card
    :style="smUp ? 'background-color: transparent; padding-top: 20px; margin-bottom: 50px':''"
    flat
  >
    <v-layout
      :style="smUp ? 'max-width: 85%; margin-top: 0%': 'margin-top: 2.5%'"
      wrap
      row
      :justify-center="!smUp"
      :justify-start="smUp"
    >
      <v-flex xs12 sm6 style="margin-left: -4%;">
        <v-layout row :justify-center="!smUp" :justify-start="smUp">
          <p v-text="'Servicios comerciales'" class="view_title_text" />
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-container>
        <v-card flat :style="smUp ? 'margin-left: -8%;':''">
          <v-layout row style="margin: 0;">
            <v-flex sm6 pa-3>
              <v-text-field
                style="margin: 25px"
                height="50px"
                v-model="user_search"
                color="secondary"
                outlined
                hide-details
                :append-icon="'mdi-magnify'"
                label="Búsqueda general"
              />
            </v-flex>
            <v-flex sm1></v-flex>
            <v-flex xs12 sm5>
              <v-layout row :justify-center="!smUp" :justify-end="smUp">
                <v-btn
                  style="margin-top: 40px"
                  height="60px"
                  width="260px"
                  color="secondary"
                  @click="dialog=true"
                  dense
                >Añadir servicio</v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-container>
    </v-layout>
    <v-layout>
      <v-container>
        <!-- Start table container -->
        <div v-if="!bLoading">
          <OrderTable :items="orders_list" @update="get_clients" />
          <v-pagination style="margin-left:-5%;" v-model="current_page" :length="num_pages"></v-pagination>
        </div>
        <!-- Loading animation -->
        <v-card flat style="height: 100%; width: 100%; background-color: transparent" v-else>
          <v-layout :style="smUp ? 'max-width: 90%; margin-top: 10%':''" row justify-center>
            <v-progress-circular indeterminate rotate color="primary" size="100" width="1" />
          </v-layout>
        </v-card>
        <!-- Loading animation -->
        <!-- End table container -->
        <div
          style="position: fixed; bottom: 0;text-align: center; justify-content: center; justify-items: center; width:69%; margin-bottom:20px;"
        >
          <span style="color: black; font-size: 16px;">
            Powered by
            <a
              style="color: black;"
              href="https://sofex.com.mx/"
              target="_blank"
            >Sofex Technologies</a>
            <img
              style="margin-left: 8px;position: relative; top: 6px;height: 25px; width: 25px;"
              src="/static/sofexlogo.png"
              alt
            />
          </span>
        </div>
      </v-container>
    </v-layout>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="white">
          <v-btn icon dark @click="dialog = false">
            <v-icon color="#56984D">mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title style="color:#56984D;">Regresar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-container>
            <v-card
              style="background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 17px;"
            >
              <v-container>
                <v-tabs
                  v-if="$vuetify.breakpoint.mdAndUp"
                  grow
                  height="70"
                  v-model="currentTab"
                  active-class="active-tab"
                >
                  <v-tab
                    style="color:#000000;background-color: #FAFAFA; font-family: 'Raleway', sans-serif;font-weight:600;"
                  >{{home.manuTitle}}</v-tab>
                  <v-tab
                    style="color:#000000;background-color: #FAFAFA; font-family: 'Raleway', sans-serif;font-weight:600;"
                  >{{home.comerTitle}}</v-tab>
                </v-tabs>
                <v-tabs
                  v-else
                  fixed-tabs
                  height="70"
                  v-model="currentTab"
                  active-class="active-tab"
                >
                  <v-tab
                    style="max-width: 40%;background-color: #FAFAFA; font-family: 'Montserrat', sans-serif; font-size: 8px;"
                  >{{home.manuTitle}}</v-tab>
                  <v-tab
                    style="max-width: 40%;background-color: #FAFAFA; font-family: 'Montserrat', sans-serif; font-size: 8px;"
                  >{{home.comerTitle}}</v-tab>
                </v-tabs>

                <v-layout row style="margin: 0;" v-if="currentTab == 0">
                  <v-flex sm6 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Título'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="sTitulo"
                        color="guest_primary"
                        placeholder="Escribir el título del servicio"
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex sm1></v-flex>
                  <v-flex sm12 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Descripción'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-textarea
                        v-on:keyup.enter="set_login"
                        v-model="sDescripcion"
                        color="guest_primary"
                        placeholder="Escribe una descripción del producto…"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout row style="margin: 0;" v-else>
                  <v-flex sm6 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Título'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-text-field
                        v-on:keyup.enter="set_login"
                        v-model="sTitle"
                        color="guest_primary"
                        placeholder="Escribir el título del servicio"
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex sm1></v-flex>
                  <v-flex sm12 pa-3>
                    <v-layout row justify-left>
                      <p class="field_texts" v-text="'Descripción'" />
                    </v-layout>
                    <v-layout row justify-center class="password_layout">
                      <v-textarea
                        v-on:keyup.enter="set_login"
                        v-model="sDescription"
                        color="guest_primary"
                        placeholder="Escribe una descripción del producto…"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <br />
            <v-card
              style="background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 17px;"
            >

            </v-card>

            <v-layout column style="margin: 0;">
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5></v-flex>
              <v-flex xs12 sm5>
                <v-layout row :justify-center="!smUp" :justify-end="smUp">
                  <v-btn
                    style="margin-top: 40px;margin-right:4%"
                    height="60px"
                    width="260px"
                    text
                    color="red"
                    @click="dialog=false"
                    dense
                  >Cancelar</v-btn>
                  <v-btn
                    style="margin-top: 40px"
                    height="60px"
                    width="260px"
                    color="secondary"
                    @click="saveManufacture"
                    dense
                  >Guardar</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-list>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    menu13: false,
    menu14: false,
    dialog: false,
    sTitle: "",
    sDescription: "",
    sTitulo: "",
    sDescripcion: "",
    currentTab: 0,
    currentTabImage: 0,
    home: {
      manuTitle: "Español",
      comerTitle: "Inglés",
    },
    dateFormattedInicial: null,
    dateInicial: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormattedFinal: null,
    dateFinal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    orders_list: [],
    orderBy: null,
    orderList: ["Orden 1", "Orden 2"],
    user_search: "",
    current_page: 1,
    num_pages: 0,
    total_users: 0,
    clients_list: [],
    add_client: false,
    bLoading: false,
  }),
  watch: {
    dateInicial(val) {
      this.dateFormattedInicial = this.formatDate(this.dateInicial);
    },
    dateFormattedInicial(val) {
      this.dateFormattedInicial = this.formatDate(this.dateInicial);
      this.getOrders();
    },
    dateFinal(val) {
      this.dateFormattedFinal = this.formatDate(this.dateFinal);
    },
    dateFormattedFinal(val) {
      this.dateFormattedFinal = this.formatDate(this.dateFinal);
      this.getOrders();
    },
    current_page: function () {
      if (this.user_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    },
    user_search: _.debounce(function () {
      if (this.user_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    }, 500),
  },
  mounted() {
    this.get_clients();
  },
  methods: {
    saveManufacture() {
      let that = this;

      var axios = require("axios");
      var data = JSON.stringify({
        english: { description: this.sDescription, name: this.sTitle },
        spanish: { description: this.sDescripcion, name: this.sTitulo },
        bAvailable: true,
      });

      var config = {
        method: "post",
        url: "https://api.genvamex.com/api/products",
        headers: {
          Authorization: this.usr_token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      db(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          const alert = {
            color: "success",
            text: "Elemento creado",
          };
          that.$store.commit("toggle_alert", alert);
          that.sTitulo = "";
          that.sDescripcion = "";
          that.sTitle = "";
          that.sDescription = "";
          that.dialog = false;
          that.get_clients();
        })
        .catch(function (error) {
          console.log(error);
          const alert = {
            color: "fail",
            text: error.response.data.message,
          };
          that.$store.commit("toggle_alert", alert);
        });
    },
    getOrders() {
      console.log("getOrders");
      this.orders_list = [];
      let temp = {};
      for (let i = 0; i < 10; i++) {
        temp = {
          name: "Fabián Hernández " + i,
          date: new Date(),
          status: i % 4,
          id: i,
        };
        this.orders_list.push(temp);
      }
      console.log(this.orders_list);
    },
    get_clients() {
      this.bLoading = true;
      db.get(`${uri}/api/products`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          page: this.current_page,
          search: this.user_search,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.totalPages;
          this.orders_list = resp.data.products;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    get_clients_search() {
      this.bLoading = true;
      db.get(`${uri}/api/products`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          search: this.user_search,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });

          console.log(resp.data);

          this.num_pages = resp.data.totalPages;
          this.orders_list = resp.data.products;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
  components: {
    ClientAdd: () => import("@/components/sections/Admin/Clients/ClientAdd"),
    OrderTable: () => import("@/components/sections/Admin/Recipes/RecipeTable"),
  },
};
</script>
<style scoped>
.total_user_text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  margin-right: 10%;
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}
.search_user {
  margin-right: 10%;
}
.search_user_mobile {
  max-width: 80%;
}

.password_layout {
  width: 100%;
  margin-bottom: 10px;
}

.field_texts {
  margin-bottom: -10px;
  font-family: "Open sans", sans-serif;
  color: #000000 !important;
  font-size: 16px;
  letter-spacing: 0.1px;
}

.active-tab {
  background-color: #24347b5c !important;
}
</style>